<template>
  <div>
    <div v-if="!!callItem && activityCounterparts && activityCounterparts.length > 0"
         class="mt-3"
    >
      <!-- Title -->
      <div class="d-flex align-items-center mt-3">
        <!--Left-->
        <div class="w-25">{{ staticText.subjectLabel }}</div>
        <!--Right-->
        <div class="w-75 ml-auto">
          <b-form-input v-model="subject"
                        :placeholder="callItem.name"
                        :state="showSubjectError"
                        class="no-border shadow-sm"
                        type="text"
          ></b-form-input>
        </div>
      </div>

      <!--Date, Start & End Time-->
      <div class="d-flex mt-3 align-items-center justify-content-between">
        <VueCtkDateTimePicker
          id="meeting-date-picker-202482622"
          v-model="meetingDate"
          :label="staticText.dateLabel"
          :error="dateError"
          format="DD-MM-YYYY"
          formatted="ll"
          :only-date="true"
        />
        <VueCtkDateTimePicker
          id="meeting-start-time-picker-202482614"
          v-model="startTime"
          :label="staticText.startTimeLabel"
          :only-time="true"
          format="hh:mm a"
          formatted="hh:mm a"
          class="mx-2"
        />
        <VueCtkDateTimePicker
          id="meeting-end-time-picker-202482621"
          v-model="endTime"
          :label="staticText.endTimeLabel"
          format="hh:mm a"
          formatted="hh:mm a"
          :only-time="true"
        />
      </div>

      <!--  Meeting-Type (dropdown)-->
      <div class="d-flex align-items-center mt-3"
           v-if="meetingTypes"
      >
        <!--Left-->
        <div class="w-25">{{ staticText.meetingTypeLabel }}</div>
        <!--Right-->
        <div class="w-75 ml-auto">
          <multiselect v-model="selectedMeetingType"
                       :options="meetingTypes.options"
                       :placeholder="staticText.selectOptionPlaceholder"
                       :show-labels="false"
                       :allowEmpty="false"
                       track-by="value"
                       label="label"
          ></multiselect>
        </div>
      </div>

      <!--Attendees-->
      <div class="d-flex align-items-center mt-3">
        <!--Left-->
        <div class="w-25">{{ staticText.AttendeesLabel }}</div>
        <!--Right-->
        <div class="w-75 ml-auto" v-if="activityCounterparts">
          <BaseCounterpartSelector
            :pre-selected-counterparts="preselectedContacts"
            :items="contacts"
            :allow-empty="true"
            :allow-multiple="true"
            :allow-internal-search="false"
            :is-loading="isLoading"
            :placeholder="staticText.selectOptionPlaceholder"
            @search="searchContacts"
            @input="updateSelectedContacts"
          />
        </div>
      </div>

      <!--  Location-->
      <div class="d-flex align-items-center mt-3" v-if="locationTypes">
        <!--Left-->
        <div class="w-25">{{ staticText.locationTypeLabel }}</div>
        <!--Right-->
        <div class="w-75 ml-auto">
          <multiselect v-model="selectedLocationType"
                       :options="locationTypes.options"
                       :placeholder="staticText.selectOptionPlaceholder"
                       :show-labels="false"
                       :allowEmpty="false"
                       track-by="value"
                       label="label"
          ></multiselect>
        </div>
      </div>

      <div class="d-flex align-items-center mt-3" v-if="selectedLocationType">
        <!--Left-->
        <div class="w-25"></div>
        <!--Right-->
        <div class="w-75 ml-auto">
          <b-form-input v-model="selectedLocation"
                        :placeholder="locationPlaceholder"
                        class="no-border shadow-sm"
                        type="text"
          ></b-form-input>
          <div v-if="isGoogleCalendarConnected"
               class="d-flex mt-3 justify-content-left"
          >
            <BaoCheckbox :value="createGoogleMeetLink"
                         :label="staticText.googleMeetLinkText"
                         @input="createGoogleMeetLink=!createGoogleMeetLink"
            />
          </div>
        </div>
      </div>

      <!-- Description -->
      <div class="d-flex align-items-center mt-3">
        <!--Left-->
        <div class="w-25">{{ staticText.descriptionLabel }}</div>
        <!--Right-->
        <div class="w-75 ml-auto">
          <b-form-textarea v-model="description"
                           :placeholder="staticText.descriptionPlaceholder"
                           class="no-border shadow-sm"
          ></b-form-textarea>
        </div>
      </div>

      <!-- Internal Note -->
      <div class="d-flex align-items-center mt-3">
        <!--Left-->
        <div class="w-25">{{ staticText.internalNotesLabel }}</div>
        <!--Right-->
        <div class="w-75 ml-auto">
          <b-form-textarea v-model="internalNotes"
                           :placeholder="staticText.internalNotesPlaceholder"
                           class="no-border shadow-sm"
          ></b-form-textarea>
        </div>
      </div>

      <!--  Calendar integration Alerts    -->
      <div>
        <base-alert
          id="no-counterpart-alert-202402200212"
          :show-alert="!canUseCalendarIntegration"
          variant="danger"
          class="mt-3"
        >
          <template #alertMessage>
            <span class="ml-1">
              {{ staticText.activateCalenderFlagAlertStart }}
              <b-link href="mailto:cs@bao-solutions.com" target="_blank">
                <u class="text-muted">{{ staticText.activateCalenderFlagLink }}</u>
              </b-link>
              {{ staticText.activateCalenderFlagAlertEnd }}
            </span>
          </template>

        </base-alert>
        <div v-if="showCalendarWarning">
          <base-alert
            id="no-counterpart-alert-202402200212"
            :show-alert="canUseCalendarIntegration && !isGoogleCalendarConnected"
            variant="danger"
            class="mt-3"
          >
            <template #alertMessage>
              <span class="ml-1">
                {{ staticText.connectCalenderAlertStart }}
                <b-link to="/users/integrations" target="_blank">
                  <u class="text-muted">{{ staticText.connectCalenderLink }}</u>
                </b-link>
                {{ staticText.connectCalenderAlertEnd }}
              </span>
            </template>

          </base-alert>
        </div>
      </div>

      <!-- create button -->
      <div class="w-100 d-flex justify-content-end mt-3">
        <ActivityCreateButton
          :is-created="created"
          :is-creating="creating"
          :button-label="staticText.createMeetingBtn"
          :created-label="staticText.createdLabel"
          :creating-label="staticText.creatingLabel"
          :disabled="isPreviewMode"
          @create="createMeeting"
        />
      </div>
    </div>
    <div v-else>
      <base-alert
        id="no-counterpart-alert-202402200212"
        :alert-message="staticText.alertText"
        :show-alert="true"
        variant="danger"
        class="mt-3"
      ></base-alert>
    </div>
  </div>
</template>

<script>
import { ActivityCreatorMixin } from "@/apps/call/CallViewItem_components/ActivityCreatorMixin"
import moment from "moment"
import axios from "axios"
import { currentCallStore } from "@/store/services/callStore"
import { mapGetters } from "vuex"
import { createCalendarEvent, getUserCalendar } from "@/apps/user/integrations/api"
import { CalendarTypeEnum } from "@/apps/user/integrations/constants"
import BaoCheckbox from "@/apps/base/BaoCheckbox"

export default {
  name: "MeetingActivity",
  mixins: [ActivityCreatorMixin],
  components: { BaoCheckbox },
  data () {
    return {
      showCalendarWarning: false,
      isLoading: false,
      dateError: null,
      meetingDate: moment().format("DD-MM-YYYY"),
      startTime: moment().format("hh:mm a"),
      endTime: moment().add(30, "minute").format("hh:mm a"),
      contacts: [],
      meetingTypes: null,
      locationTypes: null,
      selectedLocationType: null,
      selectedLocation: "",
      selectedContacts: [],
      preselectedContacts: [],
      selectedMeetingType: null,
      isGoogleCalendarConnected: false,
      createGoogleMeetLink: true,
      description: "",
      internalNotes: "",
      staticTextDefault: {
        googleMeetLinkText: "Add Google Meet link",
        AttendeesLabel: "Attendees",
        subjectLabel: "Title",
        descriptionLabel: "Description",
        descriptionPlaceholder: "Add description",
        internalNotesLabel: "Internal Notes",
        internalNotesPlaceholder: "Add internal notes",
        linkToActivityLabel: "Link to Meeting",
        warningMessage: "Error in creating Meeting.",
        emptySubjectMessage: "Title is required.",
        createMeetingBtn: "Create Meeting",
        createdLabel: "Meeting Created",
        creatingLabel: "Creating Meeting...",
        alertText: "To create a meeting select a counterpart in the top-right corner.",
        dateLabel: "Select date",
        startTimeLabel: "Select start time",
        endTimeLabel: "Select end time",
        meetingTypeLabel: "Meeting type",
        locationTypeLabel: "Location",
        addPhoneNumberPlaceholder: "Add phone number",
        addAddressPlaceholder: "Add address",
        invalid: "Invalid",
        inputErrorTitle: "Input Error",
        selectOptionPlaceholder: "Select option",
        connectCalenderLink: "connected to bao",
        connectCalenderAlertStart: "A corresponding meeting will be created in your calendar only if it is ",
        connectCalenderAlertEnd: ". Otherwise the meeting is created in Hubspot without an actual calendar invite.",
        activateCalenderFlagLink: "contact your customer success team",
        activateCalenderFlagAlertStart: "A corresponding meeting can be created to your calendar only if it is connected to bao. Please ",
        activateCalenderFlagAlertEnd: " for this. Otherwise the meeting is created in Hubspot without an actual calendar invite."
      }
    }
  },
  props: {
    callItem: {
      type: Object,
      required: true
    }
  },
  watch: {
    activityCounterparts (val) {
      if (val && val.length > 0) {
        this.selectedCounterpart = val[0]
        this.updatePreselectedContacts(val)
        this.updateSelectedContacts(this.preselectedContacts)
      }
    }

  },
  computed: {
    ...mapGetters({
      canUseCalendarIntegration: "auth/canUseCalendarIntegration",
      user: "auth/user"
    }),
    activityCounterparts () {
      return currentCallStore.counterparts
    },
    locationPlaceholder () {
      if (this.selectedLocationType && this.selectedLocationType.value === "PHONE") {
        return this.staticText.addPhoneNumberPlaceholder
      }
      return this.staticText.addAddressPlaceholder
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  async mounted () {
    // check if calendar integration is active if user has access to calendar integration.
    if (this.canUseCalendarIntegration) {
      await this.getUserCalendarAuthData()
    }

    this.itemNotes = this.callItem.notes
    this.subject = this.callItem.name
    await this.getMeetingFields()
    if (this.activityCounterparts.length > 0) {
      this.selectedCounterpart = this.activityCounterparts[0] // activity is created only for one (first) counterpart
      this.updatePreselectedContacts(this.activityCounterparts)
    }
  },
  methods: {
    async getUserCalendarAuthData () {
      const response = await getUserCalendar({ user_id: this.user.id })
      if (response && response.data) {
        const calendar = response.data[0]
        if (calendar) {
          this.isGoogleCalendarConnected = calendar.is_active && calendar.calendar_type === CalendarTypeEnum.GOOGLE
        }
        this.showCalendarWarning = !this.isGoogleCalendarConnected
      }
    },
    updatePreselectedContacts (counterparts) {
      const contacts = counterparts.filter(counterpart => counterpart && counterpart.type === "Contact")
      this.preselectedContacts = this.concatenateWithoutDuplicates(this.preselectedContacts, contacts)
    },
    updateSelectedContacts (newContacts) {
      this.selectedContacts = this.concatenateWithoutDuplicates(this.selectedContacts, newContacts)
    },
    concatenateWithoutDuplicates (arr1, arr2) {
      const filteredArr2 = arr2.filter(
        arr2Item => !arr1.some(arr1Item => arr1Item && arr1Item.id === arr2Item.id)
      )
      return arr1.concat(filteredArr2)
    },
    async searchContacts (queryTerm) {
      if (queryTerm.length < 3) return
      try {
        this.isLoading = true
        const response = await axios.get("/api/oauthservices/hubspot/get_lookup_values/", {
          params: {
            object_types: ["Contact"],
            query_term: queryTerm
          }
        })
        this.contacts = response.data.results
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
      }
    },
    async getMeetingFields () {
      try {
        const url = "/api/oauthservices/hubspot/get_meeting_fields"
        const { data } = await this.axios.get(url)
        this.meetingTypes = data.find(field => field.id === "hs_activity_type")
        this.locationTypes = data.find(field => field.id === "hs_meeting_location_type")
      } catch {
        this.showWarningMessage("Something went wrong", "Error in fetching Meeting data")
      }
    },
    checkMeetingErrors () {
      const errorTitle = this.staticText.inputErrorTitle

      if (!this.subject) {
        this.showWarningMessage(errorTitle, `${this.staticText.invalid} ${this.staticText.subjectLabel}`)
        return true
      }
      if (!this.meetingDate) {
        this.showWarningMessage(errorTitle, `${this.staticText.invalid} ${this.staticText.dateLabel}`)
        return true
      }
      if (!this.startTime) {
        this.showWarningMessage(errorTitle, `${this.staticText.invalid} ${this.staticText.startTimeLabel}`)
        return true
      }
      if (!this.endTime || this.endTime < this.startTime) {
        this.showWarningMessage(errorTitle, `${this.staticText.invalid} ${this.staticText.endTimeLabel}`)
        return true
      }
      return false
    },
    getMeetingData () {
      const selectedDate = moment(this.meetingDate, "DD-MM-YYYY")
      const selectedStartTime = moment(this.startTime, "hh:mm a")
      const selectedEndTime = moment(this.endTime, "hh:mm a")

      return {
        hs_timestamp: moment().toISOString(), // required field
        counterpart: this.selectedCounterpart,
        meeting_attendees: this.concatenateWithoutDuplicates(this.selectedContacts, [this.selectedCounterpart]),
        hs_meeting_title: this.subject,
        hs_meeting_start_time: selectedDate.hour(selectedStartTime.hour()).minute(selectedStartTime.minutes()).toISOString(),
        hs_meeting_end_time: selectedDate.hour(selectedEndTime.hours()).minute(selectedEndTime.minutes()).toISOString(),
        hs_activity_type: this.selectedMeetingType ? this.selectedMeetingType.value : null,
        hs_meeting_location_type: this.selectedLocationType ? this.selectedLocationType.value : null,
        hs_meeting_location: this.selectedLocation,
        hs_meeting_body: this.description,
        hs_internal_meeting_notes: this.internalNotes
      }
    },
    getContactEmailsForCalendar (meetingData) {
      return meetingData.meeting_attendees.map(contact => {
        const properties = contact.external_resource && contact.external_resource.properties
          ? contact.external_resource.properties
          : null

        return properties
          ? { email: properties.email && properties.email.value ? properties.email.value : properties.email }
          : null
      }).filter(item => !!item && !!item.email)
    },
    getConferenceData () {
      return {
        createRequest: {
          requestId: "meeting-created-timestamp-" + moment().unix().toString(),
          conferenceSolutionKey: { type: "hangoutsMeet" }
        }
      }
    },
    getCalendarData (meetingData) {
      const calenderData = {
        calendar_type: CalendarTypeEnum.GOOGLE,
        summary: meetingData.hs_meeting_title,
        location: meetingData.hs_meeting_location,
        description: meetingData.hs_meeting_body,
        start: { dateTime: meetingData.hs_meeting_start_time },
        end: { dateTime: meetingData.hs_meeting_end_time },
        attendees: this.getContactEmailsForCalendar(meetingData),
        reminders: {
          useDefault: false,
          overrides: [
            { method: "email", minutes: 24 * 60 },
            { method: "popup", minutes: 10 }
          ]
        }
      }
      if (this.isGoogleCalendarConnected && this.createGoogleMeetLink) {
        calenderData.conferenceData = this.getConferenceData()
      }
      return calenderData
    },
    async createMeeting () {
      if (this.checkMeetingErrors()) return

      const meetingData = this.getMeetingData()
      const calendarData = this.getCalendarData(meetingData)
      const eventRes = await this.createGoogleCalendarEvent(calendarData)

      if (eventRes && eventRes.data && eventRes.data.htmlLink) {
        meetingData.hs_meeting_external_url = eventRes.data.htmlLink
      }
      if (eventRes && eventRes.data && eventRes.data.hangoutLink) {
        meetingData.hs_meeting_location = this.selectedLocation = eventRes.data.hangoutLink
      }
      await this.createActivity("meeting", meetingData)
    },

    async createGoogleCalendarEvent (calenderData) {
      if (!this.canUseCalendarIntegration) return

      if (this.canUseCalendarIntegration && !this.isGoogleCalendarConnected) {
        await this.getUserCalendarAuthData()
      }

      if (this.canUseCalendarIntegration && this.isGoogleCalendarConnected) {
        try {
          return await createCalendarEvent(calenderData)
        } catch (error) {
          console.error(error)
        }
      }
    },

    getNotesToUpdate () {
      let notes = ""
      notes += `<p>${this.staticText.subjectLabel}: ${this.subject}</p>`
      notes += `<p>${this.staticText.createdLabel}: ${this.meetingDate} ${this.startTime} - ${this.endTime}</p>`
      if (this.description) {
        notes += `<p>${this.staticText.descriptionLabel}: ${this.description}</p>`
      }
      if (this.selectedLocation) {
        notes += `<p>${this.staticText.locationTypeLabel}: ${this.selectedLocation}</p>`
      }
      return notes
    }
  }
}
</script>

<style scoped>

</style>
