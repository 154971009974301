<template>
  <div class="w-100 h-100">

    <div>
      <h4 id="title-202012281150">
        {{staticText.title}}
      </h4>
    </div>

    <div class="mt-3 row border-top">
      <div class="col">
        <b-btn :id="'neutral-option-'+index"
               v-for="(item, index) in neutralOptions"
               :key="'key-neutral-option-' + index"
               :class="isItemSelected(item) ? 'neutralBtn' : 'outline-neutralBtn'"
               class="w-100 mx-auto my-2 p-3"
               @click="selectResult(item)"
        >
          <div class="d-flex">
            <div class="mr-3">
              <i v-if="isItemSelected(item)"
                 class="fas fa-circle"
              ></i>

              <i v-else
                 class="far fa-circle"
              ></i>
            </div>

            <div>
              {{item.text}}
            </div>

          </div>
        </b-btn>
      </div>

      <div class="col border-left">
        <b-btn :id="'positive-option-'+index"
               v-for="(item, index) in positiveOptions"
               :key="'key-positive-option-' + index"
               :class="isItemSelected(item)? 'successBtn' : 'outline-successBtn'"
               class="w-100 mx-auto my-2 p-3"
               @click="selectResult(item)"
        >
          <div class="d-flex">
            <div class="mr-3">
              <i v-if="isItemSelected(item)"
                 class="fas fa-circle"
              ></i>

              <i v-else
                 class="far fa-circle"
              ></i>
            </div>

            <div>
              {{item.text}}
            </div>

          </div>
        </b-btn>
      </div>
    </div>

    <!-- Save and Cancel buttons -->
    <div class="border-top d-flex justify-content-between pt-2">
      <b-btn class="w-25 border"
             @click="sendCancel"
      >
        {{staticText.backBtn}}
      </b-btn>

      <div id="tooltip-target-202012281151"
           class="w-25"
      >
        <b-btn :disabled="!result"
               variant="primary"
               class="w-100"
               @click="saveCallResult"
        >
          {{staticText.saveBtn}}
        </b-btn>
      </div>

      <b-tooltip v-if="!result"
                 target="tooltip-target-202012281151"
      >
        {{staticText.selectResultTooltip}}
      </b-tooltip>
    </div>
  </div>
</template>

<script>
import axios from "axios"
import { mapGetters } from "vuex"

export default {
  name: "CallResultSelector",
  props: {
    callId: {
      type: Number,
      required: true,
      default: null
    }
  },
  data () {
    return {
      axios,
      staticTextDefault: {
        title: "Select the conversation result",
        selectResultTooltip: "Select result to proceed",
        saveBtn: "Save",
        backBtn: "Back"
      },
      allOptions: [],
      result: null
    }
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      isUserAuthenticated: "auth/isUserAuthenticated"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    filteredOptions () {
      return this.allOptions.filter(item => item.active)
    },
    neutralOptions () {
      return this.filteredOptions.filter(item => item.rating === 0).sort((a, b) => a.id - b.id)
    },
    positiveOptions () {
      return this.filteredOptions.filter(item => item.rating > 0).sort((a, b) => a.id - b.id)
    }
  },
  mounted () {
    this.loadResultItems()
  },
  methods: {
    isItemSelected (item) {
      return this.result === item
    },
    sendCancel () {
      this.$emit("cancel")
    },
    selectResult (item) {
      this.result = item
    },
    saveCallResult () {
      const that = this
      return new Promise((resolve, reject) => {
        const url = "/api/callresults/"
        const data = { call: this.callId, result_item: that.result.id }
        that.axios.post(url, data).then(() => {
          that.$emit("savedCallResult", that.result.text)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    loadResultItems () {
      const that = this
      return new Promise((resolve, reject) => {
        that.axios.get("/api/resultitems/").then(response => {
          that.allOptions = response.data.results
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">
.successBtn {
  background-color: $green10;
  opacity: 0.8;
  color: $green;
  border: 1px solid $green;
  box-sizing: border-box;
  border-radius: 22px;
}

.outline-successBtn {
  background-color: $green01;
  opacity: 0.8;
  color: $green;
  border: 1px solid $green;
  box-sizing: border-box;
  border-radius: 22px;
}

.neutralBtn {
  color: $orange;
  background-color: $orange10;
  opacity: 0.8;
  border: 1px solid $orange;
  box-sizing: border-box;
  border-radius: 22px;
}

.outline-neutralBtn {
  color: $orange;
  background-color: $orange01;
  opacity: 0.8;
  border: 1px solid $orange;
  box-sizing: border-box;
  border-radius: 22px;
}

</style>
