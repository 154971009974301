<template>
  <div
    :class="['playbook-name', { 'cursor-pointer' : !isPreviewMode }]"
    @click="handlePlaybookTitleClicked"
  >
    <b-btn
      v-if="isPreviewMode"
      variant="secondary"
      class="back-button"
      @click="$emit('delete-call-and-return-to-configurator')"
    >
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'back-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15 18l-6-6 6-6" stroke="#555775" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      {{ staticText.backLabel }}
    </b-btn>
    <span class="top-bar-title font-size-14">
      {{ playbook.name }}
    </span>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!isPreviewMode" v-bind:class="'ml-1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M9.95 2.657l3.254 3.255-8.239 8.239-3.253-3.255L9.95 2.657zm5.724-.785L14.222.421a1.44 1.44 0 00-2.034 0l-1.39 1.39 3.254 3.255 1.622-1.622a1.11 1.11 0 000-1.572zM.009 15.471a.37.37 0 00.448.44l3.627-.88-3.253-3.254-.822 3.694z" fill="#AAABBA"/></svg>
    <span
      v-else
      class="ml-2 preview-label"
    >
      {{ staticText.previewMode }}
    </span>
  </div>
</template>

<script>

export default {
  name: "PlaybookTitle",
  data () {
    return {
      staticTextDefault: {
        previewMode: "Preview Mode",
        backLabel: "Back"
      }
    }
  },
  props: {
    playbook: {
      type: Object
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    isPreviewMode () {
      return !!this.$route.query.previewMode
    }
  },
  methods: {
    handlePlaybookTitleClicked () {
      if (!this.isPreviewMode) this.$emit("open-playbook-selection-modal")
    }
  }
}
</script>

<style lang="scss" scoped>

.playbook-name {
  display: flex;
  align-items: center;
  max-width: 100%;
  .top-bar-title {
    color: #555775;
    font-weight: 700;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media (min-width: 992px) and (max-width: 1200px) {
      max-width: 150px;
    }
    @media (min-width: 1200px) and (max-width: 1380px) {
      max-width: 200px;
    }
    @media (min-width: 1380px) {
      max-width: 340px;
    }
  }
  .preview-label {
    background-color: $slate10;
    border-radius: 4px;
    padding: 4px;
    color: $slate80;
    font-size: 12px;
    line-height: 14.4px;
  }
  .back-button {
    padding: 8px 16px 8px 8px;
    margin-right: 8px;
    display: flex;
    align-items: center;
  }
  svg {
    width: 16px;
  }
}

</style>
