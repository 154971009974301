<template >
  <div id="web-app-call-view-21851030"
       class="top-nav-wrapper w-100"
  >
    <!--control buttons-->
    <b-btn  v-b-toggle.sidebar-left
            id="agenda-button-202211300112"
            class="bg-transparent col-2 p-0 text-left d-lg-none d-flex align-items-center"
    >
      <i class="fas fa-list"
         v-b-tooltip.hover="{customClass: 'd-md-none'}"
         :title="staticText.agendaLabel"
      ></i>
      <span class="d-none d-md-block ml-1">{{ staticText.agendaLabel }}</span>
    </b-btn>

    <div class="col-8 d-flex justify-content-center">
      <playbook-title
        v-if="playbook"
        :playbook="playbook"
        @open-playbook-selection-modal="$emit('open-playbook-selection-modal')"
        @delete-call-and-return-to-configurator="$emit('delete-call-and-return-to-configurator')"
      />
    </div>

    <b-btn v-b-toggle.sidebar-right
           id="shortcuts-button-202211300112"
           class="col-2 bg-transparent p-0 text-right d-md-none"
    >
      <i class="fas fa-bolt"
         v-b-tooltip.hover
         :title="staticText.shortcuts"
      ></i>
    </b-btn>

    <!--Call Navigation sidebar-->
    <b-sidebar
      id="sidebar-left"
      class="d-lg-none"
      no-header
      backdrop
    >
      <template #default="{ hide }">
        <div class="mx-2">
          <b-btn class="d-flex align-items-center bg-transparent p-1 ml-auto w-100"
                 @click="hide"
          >
            <span class="col justify-content-center">{{ staticText.agendaLabel }}</span>
            <i class="fa fa-angle-left"></i>
          </b-btn>
          <slot name="call-navigation"></slot>
        </div>
      </template>
    </b-sidebar>

    <!--Shortcuts sidebar-->
    <b-sidebar id="sidebar-right"
               class="d-md-none"
               right
               no-header
               backdrop
               lazy
    >
      <template #default="{ hide }">
        <div class="mx-2">
          <b-btn class="d-flex align-items-center bg-transparent p-1 w-100"
                 @click="hide"
          >
            <i class="fa fa-angle-right"></i>
            <span class="col justify-content-center">{{ staticText.shortcuts }}</span>
          </b-btn>
          <slot name="shortcuts"></slot>
        </div>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import PlaybookTitle from "./PlaybookTitle.vue"

export default {
  components: { PlaybookTitle },
  name: "CallViewHeaderBlock",
  data () {
    return {
      staticTextDefault: {
        agendaLabel: "Agenda",
        shortcuts: "Shortcuts"
      }
    }
  },
  props: {
    playbook: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">

.top-nav-wrapper {
  display: flex;
  align-items: center;
}

.b-sidebar-outer {
  z-index: 1 !important;
}

.b-sidebar-outer ::v-deep(.bg-light) {
  background-color: $grey !important;
  border-radius: 20px !important;
  padding-top: 5px !important;
}

.b-sidebar-outer ::v-deep(.bg-dark) {
  background: unset !important;

}

.max-width-70px {
  max-width: 70px
}

</style>
