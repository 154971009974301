import { render, staticRenderFns } from "./CollapsableAgenda.vue?vue&type=template&id=40b5122a&scoped=true&"
import script from "./CollapsableAgenda.vue?vue&type=script&lang=js&"
export * from "./CollapsableAgenda.vue?vue&type=script&lang=js&"
import style0 from "./CollapsableAgenda.vue?vue&type=style&index=0&id=40b5122a&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40b5122a",
  null
  
)

export default component.exports