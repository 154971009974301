<template>
  <!-- Control Panel -->
  <div id="control-panel-202208171000" class="text-center mt-5 min-width-220">
    <div
      id="last-save-display-202014121630"
      class="position-relative last-save-display"
    >
      <div id="last-saved-202014121630">
        <span class="last-save-display-title">
          {{ staticText.lastSavedLabel }}:
        </span>
        <div class="last-save-display-value">
          {{ updatedAt }}
        </div>
      </div>

      <div
        id="saving-indicator-div-202014121630"
        class="w-100 position-absolute ml-1"
      >
        <b-alert
          id="saving-indicator-202014121630"
          :show="dismissCountDownForSave > 0"
          class="p-0"
          variant="success"
          fade
          @dismissed="dismissCountDownForSave = 0"
        >
          {{ staticText.savingAlert }}
        </b-alert>
      </div>
    </div>
  </div>
</template>

<script>

import { currentCallStore } from "@/store/services/callStore"

export default {
  name: "ControlPanel",
  data () {
    return {
      staticTextDefault: {
        savingAlert: "Saving ...",
        lastSavedLabel: "Last saved"
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    updatedAt () {
      return currentCallStore.call.updated_at
    },
    dismissCountDownForSave () {
      return currentCallStore.dismissCountDownForSave
    }
  }
}
</script>

<style scoped lang="scss">

.last-save-display {
  padding: 19px;
  border: 1px solid $slate10;
  box-sizing: border-box;
  border-radius: 12px;
}

.last-save-display-title {
  color: $slate86;
}

.last-save-display-value {
  color: $slate60;
}

</style>
