<template>
  <multiselect v-model="selectedCounterpart"
               :options="items"
               :show-labels="false"
               :allowEmpty="allowEmpty"
               :multiple="allowMultiple"
               :placeholder="placeholder"
               :internalSearch="allowInternalSearch"
               :loading="isLoading"
               track-by="id"
               class="my-auto"
               @search-change="query => $emit('search', query)"
  >
    <template #singleLabel="{option}"
    >
      <counterpart-display-cell
        :name="option.name"
        :type="option.type"
        :crm_service="option.crm_service"
      ></counterpart-display-cell>
    </template>

    <template #option="{option}"
    >
      <counterpart-display-cell
        :name="option.name"
        :type="option.type"
        :crm_service="option.crm_service"
      ></counterpart-display-cell>
    </template>

    <template #tag="{ option, remove }"
    >
      <span class="custom__tag d-flex justify-content-between mb-1">
        <span class="w-100">
          <counterpart-display-cell
            :name="option.name"
            :type="option.type"
            :direct_link="option.direct_link"
            :crm_service="option.crm_service"
          ></counterpart-display-cell>
        </span>

        <span class="custom__remove"
              @click="remove(option)"
        >
          <i class="far fa-times-circle"></i>
        </span>
      </span>
    </template>

    <span slot="noResult">
      {{ staticText.noResultsText }}
    </span>

    <span slot="noOptions">
      {{ staticText.listEmptyText }}
    </span>
  </multiselect>
</template>

<script>

import CounterpartDisplayCell from "@/apps/call/CounterpartDisplayCell"

const Multiselect = () => import("vue-multiselect")

export default {
  name: "BaseCounterpartSelector",
  components: { Multiselect, CounterpartDisplayCell },
  props: {
    preSelectedCounterparts: {
      type: [Object, Array],
      required: false,
      default: () => {
      }
    },
    items: { // counterparts
      type: Array,
      required: true
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    allowMultiple: {
      type: Boolean,
      default: false
    },
    allowInternalSearch: {
      type: Boolean,
      default: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "Select option"
    }
  },
  data () {
    return {
      selectedCounterpart: null,
      staticTextDefault: {
        listEmptyText: "",
        noResultsText: ""
      }
    }
  },
  watch: {
    selectedCounterpart (val, oldVal) {
      if (val && val !== oldVal) {
        this.$emit("input", val)
      }
    },
    preSelectedCounterparts: {
      immediate: true,
      handler (val, oldVal) {
        if (val && val !== oldVal) {
          this.selectedCounterpart = val
        }
      }
    }
  },

  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped>

</style>
