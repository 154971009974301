<template>
  <div v-if="counterparts">
    <div class="d-flex align-items-center justify-content-between">
      <div
        v-if="counterparts.length"
        id="first-selected-counterpart"
        class="d-flex align-items-center"
      >
        <div class="d-flex align-items-center mr-2">
          <component
            :is="getCrmLogo(firstCounterpart.crm_service)"
            class="crm-logo crm-logo__slate mr-2"
          />
          <i :class="['wh-16px', counterpartIcon]"></i>
        </div>
        <div class="d-flex flex-column">
          <div class="truncate">
            <a v-if="!!firstCounterpart.direct_link"
               :href="firstCounterpart.direct_link"
               target="_blank"
            >
              {{ firstCounterpart.name }}
            </a>

            <div v-else>
              {{ firstCounterpart.name }}
            </div>
          </div>
          <div v-if="counterparts.length > 1">
            <div :id="'other-counterparts-label'"
                 class="text-muted cursor-pointer font-size-12"
                 style="width: fit-content"
            >
              {{ getOthersText }}
            </div>
            <b-tooltip
              :target="'other-counterparts-label'"
              custom-class="call-view-other-counterparts-tooltip"
            >
              <div class="other-counterparts"
                   v-for="(counterpart, index) in counterparts.slice(1)" :key="index">
                <counterpart-display-cell
                  :name="counterpart.name"
                  :type="counterpart.type"
                  :crm_service="counterpart.crm_service"
                  :direct_link="counterpart.direct_link"
                ></counterpart-display-cell>
              </div>
            </b-tooltip>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <div class="mx-2">
            <b-btn
              @click="openCounterpartSelectionModal"
              class="bg-transparent p-0 wh-16px"
            >
              <img src="@/assets/svgs/edit-pen.svg">
            </b-btn>
          </div>
          <div v-if="canStoreCRMData && counterparts.length > 0 && canUseSaveToCrmFeature">
            <progress-indicator
              v-if="isLoadingCounterpartData"
              :small=true
              :showLoadingLabel="false"
            ></progress-indicator>
            <div v-else>
              <div v-if="isCounterpartDataLoaded"
                   class="d-inline-flex"
              >
                <b-btn id="clear-CRM-data-button"
                       class="bg-transparent p-0 mr-1 wh-16px"
                       @click="showClearCrmDataWarning = true"
                >
                  <img src="@/assets/svgs/clear-crm-data.svg">
                </b-btn>
                <b-tooltip
                  target="clear-CRM-data-button"
                  :title="staticText.clearAllCRMDataLabel"
                  :delay="{ show: 100, hide: 400 }"
                  triggers="hover"
                ></b-tooltip>
              </div>
              <div v-else
                   class="d-inline-flex"
              >
                <b-btn id="load-CRM-data-button"
                       class="bg-transparent p-0 mr-1 wh-16px"
                       @click="sortSelectedCounterparts()"
                >
                  <img src="@/assets/svgs/download-crm-data.svg">
                </b-btn>
                <b-tooltip
                  target="load-CRM-data-button"
                  :title="staticText.loadAllCRMDataLabel"
                  :delay="{ show: 100, hide: 400 }"
                  triggers="hover"
                ></b-tooltip>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else
           class="d-flex flex-column"
      >
        <span class="no-counterpart">
          {{ staticText.noCounterpartText }}
        </span>
        <button
          class="select-counterpart-button"
          @click="openCounterpartSelectionModal"
        >
          {{ staticText.selectCounterpartsLabel }}
        </button>
      </div>
    </div>

    <!-- Modal to load CRM data when multiple counterparts of the same crm_type exists -->
    <b-modal id="counterpartDataLoaderModal"
             v-model="showCounterpartDataLoaderModal"
             body-class="overflow-hidden p-0"
             size="lg"
             hide-footer
             no-close-on-backdrop
             no-close-on-esc
             centered
    >
      <counterpart-data-loader
        id="cpdataloader"
        ref="cpdataloader"
        :value="counterparts"
        @download-crm-data="downloadCrmData"
        @input="updateCounterparts"
      />
      <template v-slot:modal-header-close>
        <img src="../../../../public/img/icons/close-icon.svg"/>
      </template>
      <template #modal-title>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'download-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 2.907C0 1.302 3.88 0 8.333 0c4.454 0 8.333 1.302 8.333 2.907s-3.879 2.905-8.333 2.905C3.88 5.813 0 4.513 0 2.908zm0 7.419c1.894 1.36 5.393 1.826 7.886 1.875a6.989 6.989 0 00-.347 3.044C3.43 15.102 0 13.862 0 12.355v-2.03zm7.93 6.596c-2.488-.044-6.02-.504-7.93-1.876v2.048C0 18.698 3.88 20 8.333 20c.597 0 1.183-.024 1.75-.069a7.015 7.015 0 01-2.153-3.009zm8.715-9.087c.014-.067.021-.134.021-.202V5.599c-2.015 1.449-5.837 1.88-8.333 1.88C5.828 7.48 2.01 7.043 0 5.6v2.034c0 1.605 3.88 2.905 8.333 2.905.133 0 .266 0 .398-.003A6.992 6.992 0 0114.5 7.5c.748 0 1.47.117 2.145.335z" fill="#AAABBA"/><path fill-rule="evenodd" clip-rule="evenodd" d="M20 14.5c0 3.037-2.465 5.5-5.5 5.5A5.502 5.502 0 019 14.5C9 11.463 11.465 9 14.5 9s5.5 2.463 5.5 5.5zm-6.25.75v2c0 .416.334.75.75.75s.75-.334.75-.75v-2h2c.416 0 .75-.334.75-.75a.748.748 0 00-.75-.75h-2v-2a.748.748 0 00-.75-.75.748.748 0 00-.75.75v2h-2a.748.748 0 00-.75.75c0 .416.334.75.75.75h2z" fill="#AAABBA"/></svg>
        <span class="ml-2">{{ staticText.downloadCRMDataTitle }}</span>
      </template>
    </b-modal>

    <!-- Modal to show warning on clearing all Crm Data from crm fields-->
    <b-modal
      id="clear-crm-data-warning-202328060148"
      v-model="showClearCrmDataWarning"
      :title="staticText.warning"
    >
      <template v-slot:modal-header-close>
        <img src="../../../../public/img/icons/close-icon.svg"/>
      </template>
      <div class="d-flex align-items-center my-4">
        <img src="@/assets/svgs/warning-icon.svg">
        <p class="ml-3 mb-0">
          {{ staticText.clearCrmDataWarningMessage }}
        </p>
      </div>
      <template #modal-footer>
        <button
          class="btn btn-primary"
          @click="showClearCrmDataWarning = false"
        >
          {{ staticText.cancelLabel }}
        </button>
        <button
          class="btn btn-secondary"
          @click="clearCRMData"
        >
          {{ staticText.clearLabel }}
        </button>
      </template>
    </b-modal>

    <!-- counterpart selection modal  -->
    <counterpart-selection-modal
      ref="counterpartSelectionModal"
      :counterparts="counterparts"
      @download-crm-data="downloadCrmData"
      @set-counterparts="value => $emit('input', value)"
    />

    <!-- Modal to show warning on loading counterpart data from CRM-->
    <load-crm-data-warning-modal
      ref="loadCrmDataWarningModal"
      @ok="downloadCounterpartsData"
    />
  </div>
</template>

<script>

import CounterpartDisplayCell from "@/apps/call/CounterpartDisplayCell"
import axios from "axios"
import { mapActions, mapGetters, mapMutations } from "vuex"
import CounterpartsAppInfo from "@/apps/counterpart/index"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { currentCallStore } from "@/store/services/callStore"
import CounterpartDataLoader from "./CounterpartDataLoader"
import LoadCrmDataWarningModal from "./LoadCrmDataWarningModal"
import { getCounterpartsData, checkIfLoadedCrmFieldsExist } from "../utils"
import CrmLogoMixin from "@/apps/call_history/CallSummaryComponents/CrmLogoMixin"
import CounterpartSelectionModal from "./CounterpartSelectionModal.vue"

export default {
  name: "CounterpartDisplayBlock",
  mixins: [CrmLogoMixin],
  components: {
    CounterpartDisplayCell,
    CounterpartDataLoader,
    ProgressIndicator,
    LoadCrmDataWarningModal,
    CounterpartSelectionModal
  },
  data () {
    return {
      CounterpartsAppInfo,
      axios,
      staticTextDefault: {
        noCounterpartText: "No Counterpart selected",
        other: "Other",
        others: "Others",
        loadAllCRMDataLabel: "Load All CRM Data",
        clearAllCRMDataLabel: "Clear all CRM-data from Items",
        downloadCRMDataTitle: "Download the CRM-data",
        warning: "Warning",
        clearCrmDataWarningMessage: "Are you sure you want to clear CRM-data from items?",
        cancelLabel: "Cancel",
        clearLabel: "Clear",
        errorTitle: "Error",
        errorDescriptionMessage: "Counterpart data could not be loaded.",
        selectCounterpartsLabel: "Select now"
      },
      isLoadingCounterpartData: false,
      showCounterpartDataLoaderModal: false,
      isCounterpartDataLoaded: false,
      showClearCrmDataWarning: false
    }
  },
  watch: {
    counterparts (newValue, oldValue) {
      if (oldValue === null) {
        this.initiateDownloadCrmData(true)
      }
    }
  },
  methods: {
    ...mapActions({
      updateCounterpartsWithLoadedData: "counterpartStore/updateCounterpartsWithLoadedData"
    }),
    ...mapActions({
      sortCounterparts: "counterpartStore/sortCounterparts"
    }),
    openCounterpartSelectionModal () {
      this.$refs.counterpartSelectionModal.openModal()
    },
    initiateDownloadCrmData (isCalledAtMounted = false) {
      if (this.shouldLoadCrmData) this.sortSelectedCounterparts(isCalledAtMounted)
    },
    async initiateDownloadCounterpartsData () {
      const loadedCrmFieldsExist = await checkIfLoadedCrmFieldsExist(this.counterparts)
      if (loadedCrmFieldsExist) this.$refs.loadCrmDataWarningModal.openModal()
      else this.downloadCounterpartsData()
    },
    async downloadCounterpartsData () {
      this.isLoadingCounterpartData = true
      const newCounterparts = [...this.counterparts]

      const {
        counterpartsWithLoadedDataCount,
        counterpartsWithLoadedData,
        selectedCounterparts
      } = await getCounterpartsData({ counterparts: this.counterparts, selectedCounterparts: newCounterparts })

      if (counterpartsWithLoadedDataCount > 0) {
        await this.updateCounterpartsWithLoadedData(counterpartsWithLoadedData)
        this.downloadCrmData()
        this.$emit("input", selectedCounterparts)
      } else this.showErrorToast()

      this.isLoadingCounterpartData = false
    },
    sortSelectedCounterparts (isCalledAtMounted = false) {
      this.sortCounterparts({ counterparts: this.counterparts })

      if (this.maxCrmServiceOfSameTypeCount > 1) this.showCounterpartDataLoaderModal = true
      else if (this.counterparts.length > 0) isCalledAtMounted ? this.downloadCounterpartsData() : this.initiateDownloadCounterpartsData()
    },
    downloadCrmData () {
      this.$emit("download-crm-data")
      this.isCounterpartDataLoaded = true
    },
    updateCounterparts (newCounterparts) {
      this.$emit("input", newCounterparts)
      this.showCounterpartDataLoaderModal = false
    },
    clearCRMData () {
      this.$emit("clear-crm-data")
      this.isCounterpartDataLoaded = false
      this.showClearCrmDataWarning = false
    }
  },
  computed: {
    ...mapGetters({
      canStoreCRMData: "auth/canStoreCRMData",
      maxCrmServiceOfSameTypeCount: "counterpartStore/getMaxCrmServiceOfSameTypeCount",
      canUseSaveToCrmFeature: "auth/canUseSaveToCrmFeature"
    }),
    firstCounterpart () {
      return this.counterparts[0]
    },
    counterparts () {
      return currentCallStore.counterparts
    },
    playbookItems () {
      return currentCallStore.playbookItems
    },
    loadedCrmFields () {
      return currentCallStore.loadedCrmFields
    },
    counterpartIcon () {
      return CounterpartsAppInfo.getCounterpartIcon(this.firstCounterpart.type)
    },
    getOthersText () {
      const counterpartsCount = this.counterparts.length - 1
      let otherText = this.staticText.others
      if (counterpartsCount === 1) {
        otherText = this.staticText.other
      }
      return `+ ${counterpartsCount} ${otherText}`
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    isConversationEdited () {
      return !!this.$route.query.editConversation
    },
    shouldLoadCrmData () {
      return !this.isConversationEdited && !!this.counterparts && !!this.counterparts.length && this.canStoreCRMData
    }
  }
}

</script>

<style scoped lang="scss">

#counterpartSelectionModal___BV_modal_outer_ ::v-deep(.modal-content) {
  height: 500px !important;
  width: 360px !important;
}

.other-counterparts {
  margin-bottom: 8px;
  padding-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 120px;
  font-size: 14px;
  text-align: left;
  @media (min-width: 992px) and (max-width: 1300px) {
    width: 86px;
  }
}

.wh-16px {
  width: 16px;
}

.download-icon {
  path {
    fill: $slate80
  }
}

</style>
<style lang="scss">

.call-view-other-counterparts-tooltip {
  opacity: 1 !important;
  top: 8px !important;
  .tooltip-inner {
    max-width: 300px !important;
  }
}

.no-counterpart {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 4px;
  color: $slate80;
}

</style>
