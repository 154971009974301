<template>
  <div class="w-100 d-flex justify-content-end">

    <b-btn
      :variant="getButtonVariant"
      @click="handleButtonClicked"
      :disabled="isDisabled"
    >
      <b-spinner
        v-if="creationOngoing"
        variant="success"
        class="mr-2"
        small
      ></b-spinner>

      <i
        v-if="creationFinished"
        class="fas fa-check-circle mr-2"
      ></i>

      {{ computedLabel }}
    </b-btn>

  </div>
</template>

<script>
export default {
  name: "ActivityCreateButton",
  props: {
    isCreating: {
      type: Boolean,
      default: false
    },
    isCreated: {
      type: Boolean,
      default: false
    },
    createdLabel: {
      type: String,
      default: "Created"
    },
    creatingLabel: {
      type: String,
      default: "Creating..."
    },
    buttonLabel: {
      type: String,
      default: "Create Activity"
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    creationNotInitiated () {
      return !(this.isCreating || this.isCreated)
    },
    creationOngoing () {
      return this.isCreating && !this.isCreated
    },
    creationFinished () {
      return this.isCreated
    },
    isDisabled () {
      return this.disabled || this.creationOngoing || this.creationFinished
    },
    getButtonVariant () {
      return this.creationNotInitiated ? "primary" : "outline-success"
    },
    computedLabel () {
      return this.creationNotInitiated
        ? this.buttonLabel
        : this.creationOngoing
          ? this.creatingLabel
          : this.creationFinished ? this.createdLabel : ""
    }
  },
  methods: {
    handleButtonClicked () {
      if (this.creationNotInitiated) this.$emit("create")
    }
  }
}
</script>

<style scoped>

</style>
