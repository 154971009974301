<template>
  <div id="end-conversation-btn-202101041320"
       :title="staticText.endConversationBtn"
       :class="['end-btn-top-bar', { disabled: isPreviewMode }]"
       type="button"
       v-b-tooltip.hover.bottom
       @click="endCall"
  >
    {{ staticText.end }}
  </div>
</template>

<script>
import { currentCallStore } from "@/store/services/callStore"
import { mapGetters } from "vuex"

export default {
  name: "CallViewEndButton",
  data () {
    return {
      staticTextDefault: {
        endConversationBtn: "End Conversation",
        end: "End"
      }
    }
  },
  props: {
    isOpenFromCallItem: {
      required: false,
      default: false
    }
  },
  methods: {
    endCall () {
      if (this.isOpenFromCallItem) this.closeObjectionModalIfNeeded()
      currentCallStore.endCall()
    },
    closeObjectionModalIfNeeded () {
      this.$emit("close-objection-modal")
    }
  },
  computed: {
    ...mapGetters({
      canUseBaoAudio: "auth/canUseBaoAudio"
    }),
    currentCallStore () { return currentCallStore },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    isPreviewMode () {
      return !!this.$route.query.previewMode
    }
  }
}
</script>

<style scoped lang="scss">

.end-btn-top-bar {
  width: fit-content;
  padding: 7px 14px;
  border-radius: 16px;
  font-size: 12px;
  line-height: 14.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white80;
  background-color: rgba(188, 0, 0, 1);
  &.disabled {
    pointer-events: none;
    background-color: $orange60;
  }
}

</style>
