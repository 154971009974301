import store from "@/store"
import { currentCallStore } from "@/store/services/callStore"
import moment from "moment/moment"
import {
  COUNTERPART_FIRST_NAME_KEY, COUNTERPART_LAST_NAME_KEY,
  COUNTERPART_NAME_KEY,
  CRM_NAME_KEY,
  CURRENT_DATE_KEY,
  CURRENT_TIME_KEY,
  FIRST_NAME_KEY,
  GROUP_TYPE,
  LAST_NAME_KEY,
  OTHER_ITEM_DATA_TYPE
} from "@/apps/talkscript/components/dynamicDataIndex"
import colors from "@/assets/scss/main.scss"

export const USER_DATA_MAPPING = (user) => {
  const dateNow = moment().locale(user.language).format("Do MMMM YYYY")
  const timeNow = moment().locale(user.language).format("HH:MM a")

  const keyValueSet = {}
  keyValueSet[FIRST_NAME_KEY.en] = keyValueSet[FIRST_NAME_KEY.de] = user.first_name
  keyValueSet[LAST_NAME_KEY.en] = keyValueSet[LAST_NAME_KEY.de] = user.last_name
  keyValueSet[CURRENT_DATE_KEY.en] = keyValueSet[CURRENT_DATE_KEY.de] = dateNow
  keyValueSet[CURRENT_TIME_KEY.en] = keyValueSet[CURRENT_TIME_KEY.de] = timeNow
  return keyValueSet
}

export const COUNTERPART_DATA_MAPPING = (crmIntegrations, counterparts) => {
  const counterpartName = counterparts.length ? counterparts.map(item => item.name).join(", ") : null
  const crmService = Object.keys(crmIntegrations).length ? Object.keys(crmIntegrations).join(", ") : null

  const [counterpartFirstName, counterpartLastName] = counterparts && counterparts.length > 0
    ? extractCounterpartFirstAndLastName(counterparts)
    : [null, null]

  const keyValueSet = {}
  keyValueSet[COUNTERPART_NAME_KEY.en] = keyValueSet[COUNTERPART_NAME_KEY.de] = counterpartName
  keyValueSet[COUNTERPART_FIRST_NAME_KEY.en] = keyValueSet[COUNTERPART_FIRST_NAME_KEY.de] = counterpartFirstName
  keyValueSet[COUNTERPART_LAST_NAME_KEY.en] = keyValueSet[COUNTERPART_LAST_NAME_KEY.de] = counterpartLastName
  keyValueSet[CRM_NAME_KEY.en] = keyValueSet[CRM_NAME_KEY.de] = crmService

  return keyValueSet
}

export const getPlaybookItemsInCall = () => {
  /** returns Array of all playbook items from a call instance
   * includes items of main playbook + items of selected objections + items of selected dynamic playbooks
   * items form deeply nested playbooks are also retrieved recursively and added to the Array to be returned.
   * */
  function collectChildrenRecursive (children, result) {
    for (const child of children) {
      result.push(child)
      if (child.loadedData && child.loadedData.length > 0) {
        for (const loadedDataItem of child.loadedData) {
          if (loadedDataItem.selected_choice.workflow.children) {
            collectChildrenRecursive(loadedDataItem.selected_choice.workflow.children, result)
          }
        }
      }
    }
  }

  return currentCallStore.playbookItems.reduce((acc, playbookItem) => {
    if (playbookItem.loadedData && playbookItem.loadedData.length > 0) {
      for (const loadedDataItem of playbookItem.loadedData) {
        if (loadedDataItem.selected_choice.workflow.children) {
          collectChildrenRecursive(loadedDataItem.selected_choice.workflow.children, acc)
        }
      }
    }
    return acc
  }, [...currentCallStore.playbookItems])
}

export const injectDynamicPlaceholderValues = (rawHtml) => {
  const user = store.getters["auth/user"]
  const crmIntegrations = store.getters["auth/crmIntegrations"]
  const counterparts = currentCallStore.counterparts
  const playbookItems = getPlaybookItemsInCall()

  const USER_AND_COUNTERPART_DATA = {
    ...USER_DATA_MAPPING(user), ...COUNTERPART_DATA_MAPPING(crmIntegrations, counterparts)
  }

  // first remove existing span tags to avoid style overlapping and replace content with data-id values if any.
  rawHtml = stripSpansAndReplaceContentWithId(rawHtml)

  // Replace placeholder keys with real values
  rawHtml = rawHtml.replace(/{{([^{}]+)}}/g, (match, key) => {
    let [dataType, label, ...identifier] = key.split(",")
    label = label || key
    let value

    switch (dataType) {
      case GROUP_TYPE.USER_DATA:
        value = USER_AND_COUNTERPART_DATA[label.trim()]
        break
      case GROUP_TYPE.CRM_DATA: {
        const crmDataKeyValueSet = dynamicCrmDataMapping(counterparts)
        value = crmDataKeyValueSet[identifier[0].trim()]
        if (value && typeof value === "object") {
          value = value.name
        }
        break
      }
      case OTHER_ITEM_DATA_TYPE.NOTE:
        value = getNotesFromItem(identifier[0], playbookItems)
        break
      case OTHER_ITEM_DATA_TYPE.ANSWER:
        value = getAnswerFromItem(identifier, playbookItems)
        break
      default:
        value = USER_AND_COUNTERPART_DATA[label.trim()]
        break
    }

    const keyHtml = `<span class='rounded py-0 px-1' style="background:${colors.slate10};">${label}</span>`
    const valueHtml = `<span class='text-secondary rounded py-0 px-1' style="background: ${colors.orange12};">${value}</span>`

    // Note: value can also be 0 or false.
    return value === null || value === undefined ? keyHtml : valueHtml
  })

  return rawHtml
}

export const getAnswerFromItem = (identifier, playbookItems) => {
  const [playbookItemId, answerId] = identifier
  const playbookItem = playbookItems.find(item => String(item.id) === playbookItemId)
  if (!playbookItem || !playbookItem.selectedAnswers || playbookItem.selectedAnswers.length === 0) {
    return null
  }

  let answer
  if (answerId) {
    const selectedAnswer = playbookItem.selectedAnswers.find(selectedAnswer => String(selectedAnswer.question_id) === answerId)
    answer = selectedAnswer ? selectedAnswer.text : null
  } else {
    answer = playbookItem.selectedAnswers.map(selectedAnswer => selectedAnswer.text).join(", ") || null
  }

  return answer || null
}

export const getNotesFromItem = (playbookItemId, playbookItems) => {
  const playbookItem = playbookItems.find(item => String(item.id) === playbookItemId)
  if (playbookItem && playbookItem.notes && playbookItem.notes.length) {
    // strip html tags from notes
    const tempDiv = document.createElement("div")
    tempDiv.innerHTML = playbookItem.notes
    return tempDiv.textContent || tempDiv.innerText
  }
  return null
}
export const getDefaultCrmDataMapping = (rawCrmData, counterpartType) => {
  const crmDataKeyValues = {}
  for (const [key, value] of Object.entries(rawCrmData)) {
    crmDataKeyValues[`${counterpartType}-${key}`] = value
  }

  return crmDataKeyValues
}

export const getCrmDataMappingForHubspot = (rawCrmData, counterpartType) => {
  const crmDataKeyValues = {}
  if (rawCrmData && rawCrmData.properties && Object.keys(rawCrmData.properties).length) {
    for (const [key, value] of Object.entries(rawCrmData.properties)) {
      crmDataKeyValues[`${counterpartType}-${key}`] = value && value.value ? value.value : value
    }
  }
  return crmDataKeyValues
}
export const getCrmDataMappingForPipedrive = (rawCrmData, counterpartType) => {
  const crmDataKeyValues = {}
  for (let [key, value] of Object.entries(rawCrmData)) {
    value = !!value && value.value ? value.value : value
    if (Array.isArray(value)) {
      value = value.map(obj => obj.value).join(", ")
    }
    crmDataKeyValues[`${counterpartType}-${key}`] = value
  }

  return crmDataKeyValues
}

export const getCrmDataMapping = (counterpart) => {
  const crmService = counterpart.crm_service
  const counterpartType = counterpart.type
  const rawCrmData = counterpart.external_resource

  if (!rawCrmData) return {}

  switch (crmService) {
    case "salesforce":
      return getDefaultCrmDataMapping(rawCrmData, counterpartType)
    case "dynamics365":
      return getDefaultCrmDataMapping(rawCrmData, counterpartType)
    case "pipedrive":
      return getCrmDataMappingForPipedrive(rawCrmData, counterpartType)
    case "hubspot":
      return getCrmDataMappingForHubspot(rawCrmData, counterpartType)
    // TODO: add salesloft and zendesk CRM.
    default:
      return {}
  }
}

export const dynamicCrmDataMapping = (counterparts) => {
  /**
   * returns a set of dynamic data keys and corresponding values
   * format-> {object_type-field_key: field_value}
   * */
  let dynamicDataSet = {}
  if (counterparts && counterparts.length > 0) {
    for (const counterpart of counterparts) {
      dynamicDataSet = { ...dynamicDataSet, ...getCrmDataMapping(counterpart) }
    }
  }
  return dynamicDataSet
}

export const stripSpansAndReplaceContentWithId = (htmlContent) => {
  // Create a temporary div element
  const tempDiv = document.createElement("div")
  tempDiv.innerHTML = htmlContent

  // Select all span elements and replace them with their text content
  const spans = tempDiv.querySelectorAll("span")
  spans.forEach(span => {
    let textContent = span.textContent

    // if data-id attribute of span is not null, then replace text content with data-id
    const dataId = span.getAttribute("data-id")
    if (dataId) {
      textContent = dataId
    }
    const textNode = document.createTextNode(textContent)
    span.parentNode.replaceChild(textNode, span)
  })

  // Get the modified HTML content without span tags
  return tempDiv.innerHTML
}

export const extractCounterpartFirstAndLastName = (counterparts) => {
  const propertyMapping = {
    salesforce: { firstName: "FirstName", lastName: "LastName" },
    dynamics365: { firstName: "firstname", lastName: "lastname" },
    pipedrive: { firstName: "first_name", lastName: "last_name" },
    hubspot: { firstName: "firstname", lastName: "lastname" }
  }

  const names = {
    firstName: [],
    lastName: []
  }

  for (const counterpart of counterparts) {
    const crmService = counterpart.crm_service
    let crmData = counterpart.external_resource
    if (crmData) {
      if (crmService === "hubspot") crmData = crmData.properties

      const mapping = propertyMapping[crmService]
      if (mapping) {
        const { firstName, lastName } = mapping
        if (Object.entries(crmData).length > 0) {
          if (crmData[firstName]) names.firstName.push(crmData[firstName])
          if (crmData[lastName]) names.lastName.push(crmData[lastName])
        }
      }
    }
  }

  const firstName = names.firstName.length > 0 ? names.firstName.join(", ") : null
  const lastName = names.lastName.length > 0 ? names.lastName.join(", ") : null

  return [firstName, lastName]
}
