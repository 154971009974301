<template>
  <b-modal
    v-model="changePlaybookModal"
    hide-footer
  >
    <template v-slot:modal-header-close>
      <img src="../../../../public/img/icons/close-icon.svg" />
    </template>
    <template v-slot:modal-title>
      <div class="modalTitle">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'mb-1'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M4 1h5.625a.375.375 0 01.369.308l.006.067V7c0 .79.612 1.438 1.387 1.496l.113.004h5.625a.375.375 0 01.369.307l.006.068V17.5a1.5 1.5 0 01-1.387 1.496L16 19H4a1.5 1.5 0 01-1.496-1.387L2.5 17.5v-15c0-.79.612-1.438 1.388-1.496L4 1h5.625H4zm7.125 6V1.646a.113.113 0 01.164-.1l.028.021 5.616 5.616a.112.112 0 01.03.106.113.113 0 01-.075.08l-.035.006H11.5a.375.375 0 01-.369-.308L11.125 7V1.646 7z" fill="#2B2727" opacity=".8"/></svg>
        {{ staticText.changePlaybookLabel }}
      </div>
    </template>

    <div class="my-2">
      <div class="input-wrapper">
        <b-form-input
          v-model="playbookFilter"
          :placeholder="staticText.searchPlaceholderLabel"
          class="pr-5"
        ></b-form-input>
        <span class="search-icon">
          <img src="../../../../public/img/icons/search-icon.svg" />
        </span>
        <span class="input-label">Search</span>
      </div>
    </div>

    <div class="playbook-list">
      <div
        v-for="playbook in filteredPlaybooks"
        :key="playbook.id"
        class="playbook-item"
      >
        <p>{{ playbook.name }}</p>
        <button @click="changeActivePlaybook(playbook)">{{ staticText.selectLabel }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from "vuex"
import { sortByAlphaNumeric } from "@/apps/dashboard/chartUtils"

export default {
  name: "PlaybookSelectionModal",
  data () {
    return {
      staticTextDefault: {
        changePlaybookLabel: "Change your playbook",
        searchPlaceholderLabel: "Type to search",
        selectLabel: "Select"
      },
      playbookFilter: "",
      changePlaybookModal: false
    }
  },
  props: {
    activePlaybook: {
      type: Object,
      required: true,
      default: () => {}
    }
  },
  computed: {
    ...mapGetters({
      allPlaybooks: "playbook/getAllPlaybooks"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    },
    sortedPlaybooks () {
      return sortByAlphaNumeric(this.allPlaybooks)
    },
    filteredPlaybooks () {
      const playbookFilterRegex = new RegExp(this.playbookFilter, "i")
      return this.sortedPlaybooks.filter(playbook => playbookFilterRegex.test(playbook.name) && playbook.id !== this.activePlaybook.id)
    }
  },
  methods: {
    openModal () {
      this.changePlaybookModal = true
    },
    changeActivePlaybook (newPlaybook) {
      this.changePlaybookModal = false
      this.$emit("change-active-playbook", newPlaybook)
    }
  }
}
</script>

<style lang="scss" scoped>

.modalTitle {
  font-size: 16px;
  line-height: 20px;
}
.search-icon {
  color: $slate40;
  position: absolute;
  top: 18px;
  right: 20px;
}

.playbook-list {
  max-height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  .playbook-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    margin-bottom: 8px;
    &:last-child {
        margin-bottom: 0;
    }
    p {
      margin: 0;
      width: 80%;
      overflow-wrap: break-word;
    }
    button {
      background-color: $slate80;
      outline: none;
      color: $white;
      padding: 8px 12px;
      border: none;
      border-radius: 20px;
      opacity: 0;
      transition: 0.3s ease-in;
      &:hover {
        background-color: $slate;
      }
    }
    &:hover {
      button {
        opacity: 1;
      }
    }
  }
}
</style>
