<template>
  <div class="px-3 py-2">
    <div class="d-flex align-items-end justify-content-center">
      <!-- Arc pies -->
      <real-time-assistant-arc-pie
        v-if="isValidNumber(callTalkingShare)"
        class="col-6 p-0 word-break"
        :label="staticText.talkingShareLabel"
        :value="callTalkingShare"
      ></real-time-assistant-arc-pie>
      <real-time-assistant-arc-pie
        v-if="isValidNumber(callTalkingSpeed)"
        class="col-6 p-0 word-break"
        :label="staticText.talkingSpeedLabel"
        :value="callTalkingSpeed"
        :inner-text="'' + callTalkingSpeed.toFixed(0)"
        :total="talkingSpeedValues.max"
      ></real-time-assistant-arc-pie>
    </div>
  </div>
</template>

<script>
import RealTimeAssistantArcPie from "@/apps/call/RealTimeAssistantComponents/RealTimeAssistantArcPie"

export default {
  name: "RealTimeAssistant",
  components: {
    RealTimeAssistantArcPie
  },
  props: {
    callTalkingSpeed: {
      type: Number,
      default: 0
    },
    callTalkingShare: {
      type: Number,
      default: 0
    },
    callTextSimilarity: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      staticTextDefault: {
        talkingShareLabel: "Talking Share",
        talkingSpeedLabel: "Tempo (WPM)"
      },
      talkingSpeedValues: {
        max: 220
      }
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}
</script>

<style scoped lang="scss">

</style>
