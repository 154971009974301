import { currentCallStore } from "@/store/services/callStore"
import { strip } from "@/apps/call_history"
import axios from "axios"
import ActivityCreateButton from "@/apps/call/CallViewItem_components/ActivityCreateButton"
import BaseCounterpartSelector from "@/apps/counterpart/BaseCounterpartSelector"
import BaseAlert from "@/apps/base/BaseAlert"
import TypedInputField from "@/apps/call/CallViewItem_components/TypedInputField"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"
import moment from "moment"

const Multiselect = () => import("vue-multiselect")

export const ActivityCreatorMixin = {
  name: "ActivityCreatorMixin",
  components: {
    ActivityCreateButton,
    BaseCounterpartSelector,
    BaseAlert,
    TypedInputField,
    Multiselect,
    VueCtkDateTimePicker: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "VueCtkDateTimePicker" */ "vue-ctk-date-time-picker")
  },
  data () {
    return {
      moment,
      axios,
      utils: {
        strip
      },
      created: false,
      creating: false,
      createFailed: false,
      itemNotes: null,
      subject: null,
      users: null,
      selectedUser: null,
      selectedCounterpart: null,
      activityLink: null,
      datetimeError: false,
      activities: {
        phoneCall: {
          endPoint: "create_phone_call"
        },
        meeting: {
          endPoint: "create_meeting"
        },
        event: {
          endPoint: "create_crm_event"
        },
        task: {
          api: "/api/counterpartsnew/",
          endPoint: "create_crm_task"
        }
      },
      staticTextDefault: {
        noDatetimeError: "Date/Time invalid",
        createdLabel: "Created",
        creatingLabel: "Creating ...",
        subjectLabel: "Subject",
        linkToActivityLabel: "Link to Activity",
        listEmptyText: "",
        noResultsText: "",
        warningTitle: "Something went wrong",
        warningMessage: "Error in creating CRM Activity.",
        emptySubjectMessage: "Subject is required."
      }
    }
  },
  computed: {
    activityCounterparts () {
      return currentCallStore.counterparts
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showSubjectError () {
      if (this.subject === null) {
        return null
      } else return !!this.subject
    },
    isPreviewMode () {
      return !!this.$route.query.previewMode
    }
  },
  methods: {
    getDatetimeFormat (field) {
      if (field.crm_object_link.service_key === "salesforce" && field.raw_definition.fieldType === "datetime") {
        return "YYYY-MM-DDTHH:mm:ss.sssZ"
      }
      return ""
    },
    showWarningMessage (title, errorMsg) {
      this.showGlobalToast({
        status: "error",
        message: { title, description: errorMsg }
      })
    },
    async getActivityUsers (serviceName) {
      try {
        const response = await this.axios.get("/api/users/get_users_for_crm_activities", {
          params: { crm_service: serviceName }
        })
        this.users = response.data
        this.selectedUser = !!this.users && !!this.users.length ? this.users[0] : null
      } catch {
        this.showWarningMessage("Something went wrong", "Error in fetching activity users")
      }
    },
    updateNotes (additionalNotes = "") {
      let notes = this.itemNotes ? this.itemNotes : ""
      if (additionalNotes) {
        notes += additionalNotes
      }
      if (this.activityLink) {
        notes += `<p><a href="${this.activityLink}" target="_blank">${this.staticText.linkToActivityLabel}</a></p>`
      }

      this.itemNotes = notes
      this.$emit("notes-changed", notes)
    },
    async createActivity (activityType, payload) {
      if (this.selectedCounterpart) {
        this.creating = true
        const activity = this.activities[activityType]
        const activityEndpoint = activity.endPoint
        const baseApi = activity.api
          ? `${activity.api}${this.selectedCounterpart.id}`
          : `/api/oauthservices/${this.selectedCounterpart.crm_service}`
        try {
          const apiUrl = `${baseApi}/${activityEndpoint}`
          const response = await this.axios.post(apiUrl, payload)
          if (response.data.error) {
            this.creating = false
            this.showWarningMessage(this.staticText.warningTitle, this.staticText.warningMessage)
            return
          }
          this.creating = false
          this.created = true

          // set link url if available
          this.activityLink = response.data.activity_url

          // update Notes
          const additionalNotes = this.getNotesToUpdate()
          this.updateNotes(additionalNotes)
        } catch (error) {
          this.creating = false
          this.createFailed = true
          this.showWarningMessage(this.staticText.warningTitle, this.staticText.warningMessage)
        }
      }
    }
  }
}
