<template>
  <div class="counterparts">
    <div
      id="counterparts-list"
      class="d-flex flex-column"
    >
      <!-- Header -->
      <div class="counterparts__header">
        <p class="mb-1">
          {{ staticText.counterpartsWithSameCrmServiceAndTypeText1 }}
        </p>
        <p class="m-0">
          {{ staticText.counterpartsWithSameCrmServiceAndTypeText2 }}
        </p>
      </div>
      <!--  selected counterparts  -->
      <div class="counterparts__list">
        <div
          v-for="(value, key) in counterpartsWithSameCrmTypeAndService"
          :key="key"
        >
          <div class="crm-service">
            {{ capitalize(key) }}
          </div>
          <div
            v-for="(value, key) in value"
            :key="key"
          >
            <div class="crm-type">
              {{ capitalize(key) }}
            </div>
            <div>
              <div
                v-for="(counterpart, index) in value"
                :key="`selected-counterpart-${index}`"
              >
                <div class="d-flex justify-content-between counterpart">
                  <label
                    :for="counterpart.id"
                    class="m-0"
                  >
                    <div>
                      <counterpart-display-cell
                        :name="getCounterpartName(counterpart)"
                        :type="counterpart.type"
                        :crm_service="counterpart.crm_service"
                        :direct_link="counterpart.direct_link"
                      ></counterpart-display-cell>
                    </div>
                  </label>
                  <input
                    type="radio"
                    :id="counterpart.id"
                    :value="counterpart"
                    v-model="counterpartsWithLoadedDataClone[`${counterpart.crm_service}-${counterpart.type}`]"
                    class="ml-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Download button -->
      <div class="counterparts__download">
        <button
          class="btn btn-primary"
          :disabled="noCounterpartSelected"
          @click="initiateDownloadCounterpartsData"
        >
          <span>{{ computedDownloadLabel }}</span>
          <div
            v-if="isLoadingCounterpartData"
            class="d-flex align-items-center ml-2"
          >
            <progress-indicator
              :small=true
              :showLoadingLabel="false"
            ></progress-indicator>
          </div>
        </button>
      </div>
    </div>

    <!-- Modal to show warning on loading counterpart data from CRM-->
    <load-crm-data-warning-modal
      ref="loadCrmDataWarningModal"
      @ok="downloadCounterpartsData"
    />
  </div>
</template>

<script>
import axios from "axios"
import CounterpartDisplayCell from "../CounterpartDisplayCell"
import { mapActions, mapGetters } from "vuex"
import ProgressIndicator from "@/apps/base/ProgressIndicator"
import { currentCallStore } from "@/store/services/callStore"
import LoadCrmDataWarningModal from "./LoadCrmDataWarningModal"
import { COUNTERPART_DATA_LOADER_LABEL, checkIfLoadedCrmFieldsExist, getCounterpartsData } from "../utils"
import CounterpartDataMixin from "./CounterpartDataMixin"

export default {
  name: "CounterpartDataLoader",
  mixins: [CounterpartDataMixin],
  components: {
    CounterpartDisplayCell,
    ProgressIndicator,
    LoadCrmDataWarningModal
  },
  props: {
    value: { // selected counterparts
      type: Array,
      required: true
    }
  },
  data () {
    return {
      axios,
      selectedCounterparts: [],
      counterpartsWithLoadedDataClone: {},
      staticTextDefault: {
        counterpartsWithSameCrmServiceAndTypeText1: "Attention! You have selected more than one counterpart of the same type. However, only one data set per counterpart-type can be downloaded from CRM at a time.",
        counterpartsWithSameCrmServiceAndTypeText2: "Please select below from which of the ambiguous counterparts you want to download the data.",
        downloadLabel: "Continue",
        downloadingLabel: "Downloading..."
      },
      isLoadingCounterpartData: false
    }
  },
  computed: {
    ...mapGetters({
      counterpartsWithSameCrmTypeAndService: "counterpartStore/getCounterpartsWithSameCrmTypeAndService",
      counterpartsWithLoadedData: "counterpartStore/getCounterpartsWithLoadedData"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    loadedCrmFields () {
      return currentCallStore.loadedCrmFields
    },
    computedDownloadLabel () {
      return this.isLoadingCounterpartData ? this.staticText.downloadingLabel : this.staticText.downloadLabel
    },
    noCounterpartSelected () {
      for (const value of Object.values(this.counterpartsWithLoadedDataClone)) if (value !== null) return false
      return true
    }
  },
  mounted () {
    this.selectedCounterparts = this.value
    this.counterpartsWithLoadedDataClone = { ...this.counterpartsWithLoadedData }
  },
  methods: {
    ...mapActions({
      updateCounterpartsWithLoadedData: "counterpartStore/updateCounterpartsWithLoadedData"
    }),
    capitalize (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    },
    getCounterpartName (option) {
      return option.name ? option.name : option.crm_id
    },
    async initiateDownloadCounterpartsData () {
      const loadedCrmFieldsExist = await checkIfLoadedCrmFieldsExist(Object.values(this.counterpartsWithLoadedDataClone))
      if (loadedCrmFieldsExist) this.$refs.loadCrmDataWarningModal.openModal()
      else this.downloadCounterpartsData()
    },
    async downloadCounterpartsData () {
      this.isLoadingCounterpartData = true

      const {
        counterpartsWithLoadedDataCount,
        counterpartsWithLoadedData,
        selectedCounterparts
      } = await getCounterpartsData({
        counterparts: Object.values(this.counterpartsWithLoadedDataClone),
        selectedCounterparts: this.selectedCounterparts,
        type: COUNTERPART_DATA_LOADER_LABEL
      })

      if (counterpartsWithLoadedDataCount > 0) {
        await this.updateCounterpartsWithLoadedData(counterpartsWithLoadedData)
        this.$emit("download-crm-data")
        this.$emit("input", selectedCounterparts)
      } else this.showErrorToast()

      this.isLoadingCounterpartData = false
    }
  }
}
</script>

<style scoped lang="scss">

.counterparts {
  padding: 16px 20px;
  height: 100%;
  &__header {
    overflow-y: auto;
    margin-bottom: 10px;
  }
  &__list {
    margin: 0 -20px;
    padding: 0 20px;
    max-height: 400px;
    overflow-y: auto;
    .crm-service {
      width: 100%;
      margin-bottom: 8px;
    }
    .crm-type {
      margin-left: 10px;
      margin-bottom: 8px;
      font-size: 14px;
      text-decoration: underline;
    }
    .counterpart {
      padding-bottom: 8px;
      margin: 0 0 8px 20px;
      border-bottom: 1px solid $slate06
    }
  }
  &__download {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;
    button {
      display: flex;
      align-items: center;
    }
  }
}

</style>
