<template>
  <div id="toggle-icon-"
       class="cursor-pointer"
       @click="$emit('toggle')"
  >
    <template v-if="isEye">
      <img v-if="isActive"
           src="../../assets/svgs/eye-fill-slash.svg"
      />
      <img v-else
           src="../../assets/svgs/eye-fill.svg"
      />
    </template>

    <template v-if="isRadio">
      <img v-if="isActive"
           class="radio-button"
           src="@/assets/svgs/speechRecognition/recognition-off-btn.svg"
      />
      <img v-else
           class="radio-button"
           src="@/assets/svgs/speechRecognition/recognition-on-btn.svg"
      />
    </template>
  </div>
</template>

<script>
export default {
  name: "toggleIcons",
  props: {
    isActive: {
      type: Boolean,
      required: true
    },
    type: {
      type: String,
      default: "eye"
    }
  },
  computed: {
    isEye () {
      return this.type === "eye"
    },
    isRadio () {
      return this.type === "radio"
    }
  }
}
</script>

<style scoped>

.radio-button {
  outline: none;
  border: none;
  width: 16px;
  height: 16px;
}

</style>
