<template>
  <div :class="['row agenda-container', {'menu-expanded': showNavbar}]">
    <b-btn id="agenda-btn-202012311306"
           class="agendaBtn mx-1 p-0"
           variant="secondary"
           @click="toggleAgenda"
    >
      <img
        id="show-agenda-btn-202208031643"
        :src="showAgenda ? '/img/icons/list_slash.svg' : '/img/icons/list-ul-solid.svg'"
      >
    </b-btn>

    <b-tooltip :title="showAgenda ? staticText.hideAgenda : staticText.showAgenda"
               :boundary-padding="0"
               boundary="window"
               target="agenda-btn-202012311306"
               triggers="hover"
               placement="top"
    ></b-tooltip>

    <div :class="['agenda-menu', !showAgenda ? 'closedAgenda' : '']">

      <call-navigation :items="items"></call-navigation>

      <!-- Should be removed later with call view redesign -->
      <footer>
        <slot name="footer"></slot>
      </footer>
    </div>
  </div>
</template>

<script>
import CallNavigation from "@/apps/call/CallViewNavigation"
import { mapGetters } from "vuex"

export default {
  name: "CollapsableAgenda",
  components: { CallNavigation },
  props: {
    items: { // callItem
      type: Array,
      required: true
    }
  },
  data: () => {
    return {
      config: {
        element: "#scrollspy-nested",
        offset: 0,
        method: "position",
        throttle: 0
      },
      rerender: false,
      showAgenda: true,
      staticTextDefault: {
        hideAgenda: "Hide Agenda",
        showAgenda: "Show Agenda"
      }
    }
  },
  computed: {
    ...mapGetters({
      showNavbar: "display/isMainMenuOpen"
    }),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  mounted () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.showAgenda = localStorage.getItem("call_agenda_open") !== "false"
    },
    toggleAgenda () {
      this.showAgenda = !this.showAgenda
      localStorage.setItem("call_agenda_open", "" + this.showAgenda)
    }
  }
}
</script>

<style scoped lang="scss">
.agenda-container {
  // The padding-top is calculated to match the alignment of the menu collapse button on the nav menu
  padding-top: 16.5px;
  height: 100%;
  margin: 0 4px;
  &.menu-expanded {
    padding-top: 17px;
  }
  .agendaBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: $slate;
    background-color: $white;
    box-shadow: 6px 8px 20px 5px rgba(179, 173, 159, 0.12);
    border-radius: 20px;
    opacity: 0.4;
    &:hover {
      opacity: 0.6;
    }
  }

  .width-220px {
    width: 220px;
  }

  .tooltip.b-tooltip {
    opacity: 1 !important;
  }

  .agenda-menu {
    max-height: 100%;
    overflow-y: auto;
    max-width: calc(100% - 40px); // 40px is width of agendaBtn
    width: 150px;
    transition: 0.3s ease-in;
    @media (min-width: 1450px) {
      width: 220px;
    }
    &.closedAgenda {
      width: 0;
      visibility: hidden;
    }
  }
}
</style>
