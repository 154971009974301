import axios from "axios"
import { getCallIdFromURL, getCookie } from "@/utils/utils"
import { getUserMediaStream } from "@/utils/microphone"

const HEADERS = { "Content-Type": "application/json" }
const MEDIA_RECORDER_OPTIONS = { mimeType: "audio/webm" }
const MEDIA_RECORDER_TIMESLICE_FOR_LIVE_TS = 200

class LiveTranscriber {
  constructor () {
    this.callId = null
    this.liveTranscriptionSocket = null
    this.liveTranscriptionApiKey = null
    this.keepAliveInterval = null
    this.mediaRecorder = null
  }

  async startTranscribing (language = null) {
    if (!language) { return }

    this.callId = await getCallIdFromURL()
    await this.fetchLiveTranscriptionApiKey()
    await this.setupMediaRecorder()
    await this.setupLiveTranscriptionWebSocket(language)
  }

  async stopTranscribing () {
    await this.stopLiveTranscription()
    await this.resetState()
  }

  async fetchLiveTranscriptionApiKey () {
    const response = await axios.get(`/api/livetranscription/${this.callId}/get_transcription_key/`, {
      headers: HEADERS
    })
    const data = await response.data
    this.liveTranscriptionApiKey = data.transcription_api_key
  }

  async setupLiveTranscriptionWebSocket (language) {
    console.log("LiveTS: Setting up WebSocket")

    const baseUrlForLiveTranscription = "wss://api.deepgram.com/v1/listen"
    const queryParams = [
      "model=nova-2",
      "replace=bau:Bao&replace=Ÿousand", // Remove Ÿousand from the transcript, it is randomly added by the API
      "numerals=true"
    ]
    if (language) {
      queryParams.push(`language=${language.split("_")[0]}`)
    }
    queryParams.push("punctuate=true")

    const DG_URL = `${baseUrlForLiveTranscription}?${queryParams.join("&")}`
    console.log("API Key:", this.liveTranscriptionApiKey, "URL:", DG_URL)
    this.liveTranscriptionSocket = new WebSocket(DG_URL, ["token", this.liveTranscriptionApiKey])

    this.liveTranscriptionSocket.onopen = (event) => {
      console.log("LiveTS: WebSocket connection opened:", event)
      this.startKeepAlive()
      this.mediaRecorder.start(MEDIA_RECORDER_TIMESLICE_FOR_LIVE_TS)
    }

    this.liveTranscriptionSocket.onmessage = (event) => {
      const data = JSON.parse(event.data)
      console.log("LiveTS: Received message from WebSocket:", data)
      if (data.channel && data.channel.alternatives[0] && data.channel.alternatives[0].transcript) {
        this.sendTranscriptionToBackend(data)
      }
    }

    this.liveTranscriptionSocket.onerror = async (error) => {
      console.error("LiveTS: WebSocket encountered an error:", error)
      await this.finishTranscription()
    }

    this.liveTranscriptionSocket.onclose = async (event) => {
      console.log("LiveTS: WebSocket closed:", event, this.liveTranscriptionSocket)
      await this.finishTranscription()
    }
  }

  async setupMediaRecorder () {
    const stream = await getUserMediaStream()
    this.mediaRecorder = new MediaRecorder(stream, MEDIA_RECORDER_OPTIONS)

    this.mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0 && this.liveTranscriptionSocket && this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
        this.liveTranscriptionSocket.send(event.data)
      }
    }
  }

  async stopLiveTranscription () {
    this.stopKeepAlive()
    if (this.liveTranscriptionSocket && this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
      this.liveTranscriptionSocket.send(JSON.stringify({ type: "CloseStream" }))
    }
  }

  async sendTranscriptionToBackend (transcriptionData) {
    const url = `/api/livetranscription/${this.callId}/save_transcript/`
    await this.makeApiCall(url, transcriptionData)
  }

  async finishTranscription () {
    const summaryFormat = getCookie("favoriteSummaryFormat") || "default"
    const url = `/api/livetranscription/${this.callId}/finish_transcription/`
    await this.makeApiCall(url, { summary_format: summaryFormat })
  }

  startKeepAlive () {
    this.keepAliveInterval = setInterval(() => {
      if (this.liveTranscriptionSocket && this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
        this.liveTranscriptionSocket.send(JSON.stringify({ type: "KeepAlive" }))
        console.log("LiveTS: Sent KeepAlive message")
      }
    }, 7000)
  }

  stopKeepAlive () {
    if (this.keepAliveInterval) {
      clearInterval(this.keepAliveInterval)
      this.keepAliveInterval = null
    }
  }

  async resetMediaRecorder () {
    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop()
      await new Promise((resolve) => {
        this.mediaRecorder.onstop = () => {
          if (this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach((track) => track.stop())
          }
          resolve()
        }
      })
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = null
      this.mediaRecorder.onstop = null
      this.mediaRecorder = null
    }
  }

  async resetState () {
    this.stopKeepAlive()
    await this.resetMediaRecorder()
    if (this.liveTranscriptionSocket) {
      if (this.liveTranscriptionSocket.readyState === WebSocket.OPEN) {
        console.log("LiveTS: Closing WebSocket connection")
        this.liveTranscriptionSocket.close()
        this.finishTranscription()
      }
      this.liveTranscriptionSocket.onmessage = null
      this.liveTranscriptionSocket.onerror = null
      this.liveTranscriptionSocket.onclose = null
      this.liveTranscriptionSocket = null
      console.log("LiveTS: WebSocket reset")
    }
    this.callId = null
    this.liveTranscriptionApiKey = null
  }

  async makeApiCall (url, data) {
    try {
      const response = await axios.post(url, JSON.stringify(data), {
        headers: HEADERS
      })
      return response.data
    } catch (error) {
      console.log(`LiveTS: Error making API call to ${url}:`, error)
      throw error
    }
  }
}

export default LiveTranscriber
