<template>
  <div v-if="!!phoneNumbers"
       role="button"
       v-b-hover="handleHover"
       class="air-call-wrapper"
  >
    <div class="d-flex justify-content-between align-items-center text-white">
      <!--  loading indicator  -->
      <span class="d-flex align-items-center">
        <b-spinner v-if="loading"
                   class="mr-1 mb-2"
                   variant="light"
                   small
        ></b-spinner>

        <!--  logo -->
        <img v-else
             src="../../../public/img/aircall_logo.svg"
             width="30px"
        >

        <!--  label -->
        {{staticText.airCallBtnLabel}}
      </span>

      <!--  info -->
      <span class="text-muted ml-2"
            v-b-tooltip.hover
            :title="staticText.infoText"
      >
        <i class="fa fa-info-circle"></i>
      </span>
    </div>

    <!--errors display-->
    <div v-if="errors">
      <b-alert v-model="showDismissibleAlert"
               dismissible
      >
        {{errors}}
      </b-alert>
    </div>

    <!--  phone numbers  -->
    <div v-if="showPhoneNumbers"
         class="px-1 text-white font-12"
    >
      <div v-if="phoneNumbers.error">
        {{phoneNumbers.error}}
      </div>
      <div v-else-if="phoneNumbers.length === 0">
        {{ staticText.noPhoneNumberAvailableMsg }}
      </div>
      <div v-else
           v-for="(phoneNumber, index) in phoneNumbers"
           :key="index"
      >
        <div class="bg-transparent"
             role="button"
             @click="startCall(phoneNumber)"
        >
          {{ staticText.callText }}: {{ phoneNumber }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios"

export default {
  name: "AirCall",
  data () {
    return {
      axios,
      staticTextDefault: {
        noPhoneNumberAvailableMsg: "No valid phone numbers available. Expected format: +49xxx...",
        airCallBtnLabel: "aircall",
        callText: "Call",
        infoText: "Call directly from PC/Laptop"
      },
      showPhoneNumbers: false,
      phoneNumbers: null,
      loading: false,
      errors: null,
      showDismissibleAlert: false
    }
  },
  props: {
    counterpart: {
      type: Object,
      required: false
    }
  },
  watch: {
    counterpart (val) {
      if (val) {
        this.setPhoneNumber(val)
      } else {
        this.phoneNumbers = null
      }
    }
  },
  mounted () {
    if (this.counterpart) this.setPhoneNumber(this.counterpart)
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  methods: {
    startCall (phoneNumber) {
      this.loading = true
      this.axios.post(
        "/api/oauthservices/aircall/start_out_bound_call",
        { phone_number: phoneNumber }
      ).then(response => {
        this.loading = false
        if (response.data.error) {
          this.errors = response.data.error
          this.showDismissibleAlert = true
        }
      })
    },
    handleHover (isHovered) {
      this.showPhoneNumbers = isHovered
    },
    async setPhoneNumber (counterpart) {
      await this.retrievePhoneNumbers(counterpart).then(result => {
        this.phoneNumbers = result
      })
    },
    retrievePhoneNumbers (counterpart) {
      const url = "/api/oauthservices/" + counterpart.crm_service + "/get_phone_numbers"
      return new Promise((resolve, reject) => {
        this.axios.post(url, counterpart).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      })
    }
  }
}
</script>

<style scoped lang="scss">

.air-call-wrapper {
  background: #00B388;
  padding: 8px 12px 8px 8px;
  border-radius: 8px;
  cursor: initial;
  width: fit-content;
}

.font-12 {
  font-size: 12px;
}

</style>
