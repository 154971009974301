import { getCookie } from "@/utils/utils"

// Function to list available audio input devices, placing the default device first
export async function listValidAudioDevices () {
  const devices = await navigator.mediaDevices.enumerateDevices()
  const audioDevices = devices.filter(device => device.kind === "audioinput")

  // Find and separate the default device
  const defaultDevice = audioDevices.find(device => device.deviceId === "default")
  const nonDefaultDevices = audioDevices.filter(device => device.deviceId !== "default")

  return defaultDevice ? [defaultDevice, ...nonDefaultDevices] : nonDefaultDevices
}

// Attempt to get user media stream with timeout handling, cycling through available devices
export async function getUserMediaStream () {
  const audioDevices = await listValidAudioDevices()
  const preferredMicrophoneId = getCookie("preferredMicrophoneId")
  const maxRetries = Math.max(3, audioDevices.length) // Ensure a minimum of 3 attempts or equal to the number of valid devices
  let currentAttempt = 0

  async function attemptUserMedia (deviceId) {
    const streamPromise = navigator.mediaDevices.getUserMedia({
      audio: { deviceId: deviceId ? { exact: deviceId } : undefined }
    })
    const timeoutPromise = new Promise((resolve, reject) =>
      setTimeout(() => reject(new Error("Timeout while trying to get media stream")), 3000)
    )
    return Promise.race([streamPromise, timeoutPromise])
  }

  async function tryNextDevice () {
    if (currentAttempt >= maxRetries) {
      throw new Error("Failed to access microphone after several attempts.")
    }

    // First attempt: Try preferred device if set
    if (currentAttempt === 0 && preferredMicrophoneId) {
      const preferredDevice = audioDevices.find(device => device.deviceId === preferredMicrophoneId)
      if (preferredDevice) {
        try {
          const stream = await attemptUserMedia(preferredMicrophoneId)
          console.log("CR: ", "Microphone access granted with preferred device", preferredDevice.label)
          return stream
        } catch (error) {
          console.log("CR: ", `Failed to get microphone access with preferred device - ${error.message}`)
          // If preferred device fails, move on to cycling through devices
          currentAttempt++
        }
      }
    }

    // Use modulo to cycle through devices in case of multiple retries
    const deviceIndex = (currentAttempt - 1) % audioDevices.length
    const device = audioDevices[deviceIndex]

    try {
      const stream = await attemptUserMedia(device.deviceId)
      console.log("CR: ", "Microphone access granted with device:", device.label)
      return stream
    } catch (error) {
      console.log(
        "CR: ", `Attempt ${currentAttempt + 1}: Failed to get microphone access with ${
          device
        } - ${error.message}` // This will be equal to "Permission denied" If the user has blocked the microphone access
      )
      currentAttempt++
      return tryNextDevice()
    }
  }

  return tryNextDevice() // Start the process
}
