<template>
  <div class="d-flex">
    <div id="remove-bot-202405221143"
         v-if="!removingBot"
         class="d-flex ml-2 cursor-pointer align-items-center"
         @click="removeBotFromMeeting()">
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5 5l10 10M5 15L15 5" stroke="#7F8197" stroke-width="2" stroke-linecap="round"/></svg>
    </div>
    <div v-else class="d-flex align-items-center removing-bot ml-2">
      <b-spinner small></b-spinner>
    </div>
    <b-tooltip target="remove-bot-202405221143" triggers="hover"
               custom-class="custom-tooltip"
    >
      <div class="remove-bot">{{staticText.removeBotLabel}}</div>
    </b-tooltip>
  </div>
</template>
<script>

export default {
  name: "RemoveBot",
  props: {
    removingBot: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      staticTextDefault: {
        removeBotLabel: "Remove Bot"
      }
    }
  },
  methods: {
    removeBotFromMeeting () {
      this.$emit("remove-bot-from-meeting")
    }
  },
  computed: {
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  }
}

</script>

<style lang="scss" scoped>
.custom-tooltip{
    :deep(.tooltip-inner){
      padding: 0 !important;
    }
    :deep(.tooltip .tooltip-inner){
      padding: 0 !important;
    }
}

.removing-bot{
    color: #7F8197;
}

.remove-bot{
    padding: 8px;
    font-size: 12px;
    color: #7F8197;
}
</style>
