<template>
  <div class="w-100">
    <!-- playbook item display -->
    <div :id="item.uniqueId"
         v-if="setupComplete && !shouldHideItem"
         :class="getPlaybookItemClass"
         @mouseenter="activateItem(item)"
    >
      <slot></slot>

      <!-- Alert for unanswered required item-->
      <base-alert
        v-if="unansweredRequiredItemsExist"
        :id="'alert-202102181215-'+item.uniqueId"
        :dismissible="true"
        :alert-message="staticText.unansweredRequiredItemAlert"
        :show-alert="unansweredRequiredItemsExist"
        variant="danger"
        class="alert__message"
      ></base-alert>

      <!-- Item Header -->
      <div class="d-flex align-items-center justify-content-between item-header"
           @click="toggleOpen(item)"
      >
        <div :class="getItemNameClass"
             class="cardTitle"
        >
          {{ callViewItemIndex + 1 }}.

          <span :id="'asterisks-202102191347-'+item.uniqueId"
                v-if="item.required"
                class="color-red"
          >
            *
          </span>
          {{ item.name }}
        </div>

        <b-btn variant="outline-ligth"
               size="sm"
               class="collapse-button d-flex align-items-center justify-content-center"
        >
          <i :class="getIconForItemOpen"></i>
        </b-btn>
      </div>

      <!-- Item Content -->
      <b-collapse :id="'collapse-' + item.uniqueId"
                  v-if="item"
                  :visible="item.open"
                  class="px-3 pb-2"
      >
        <!-- Talkscript item display text-->
        <div v-if="item.display_text">
          <div
            role="button"
            class="show_hide_text"
            @click="toggleShowOrHideText"
          >
            <span>{{ showOrHideText }}</span>
            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" :class="{ 'hidden': showItemFullText }" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12.003.853A.847.847 0 0011.546.1a.86.86 0 00-.946.099L6.016 4.006 1.419.19A.857.857 0 00.213.318a.849.849 0 00.128 1.24l5.136 4.25a.859.859 0 001.086 0l5.132-4.25a.85.85 0 00.308-.705z" fill="#2A2D52" fill-opacity=".6"/></svg>
          </div>

          <transition name="animate-down">
            <div
              v-if="showItemFullText"
              v-html="replaceParagraphWithLineBreaks(item.display_text)"
              class="playbook-item__display_text"
            >
            </div>
          </transition>
        </div>

        <!-- Question item -->
        <answer-choices v-if="isItemWithAnswers(item.item_type)"
                        :item="item"
                        :level="level"
                        :main-container="mainContainer"
                        :additional-data="additionalData"
                        class="mt-2"
                        @answer-selected="(selectedAnswers) => handleAnswersChanged(selectedAnswers, item)"
                        @close-objection-modal="$emit('close-objection-modal')"
        ></answer-choices>

        <!-- Datetime Selector -->
        <div v-if="isItemType(item,'datetime_select')"
             class="mt-2"
        >
          <VueCtkDateTimePicker v-model="selectedDatetime"
                                format="YYYY-MM-DDTHH:mm:ss.sssZ"
                                right
                                :label="staticText.datetimeLabel"
                                @input="setSelectedDatetime(selectedDatetime, item)"
          ></VueCtkDateTimePicker>
        </div>

        <!-- CRM linked Item -->
        <div v-if="isItemType(item, 'crmlink') && item.linked_field">
          <CrmFieldList
            :playbook-item="item"
            :clearCrmDataCount="clearCrmDataCount"
            :downloadCrmDataCount="downloadCrmDataCount"
            @input="value => handleAnswersChanged(value, item)"
          ></CrmFieldList>
        </div>

        <!-- Dynamic Playbooks/Shortcuts -->
        <div v-if="(isItemType(item, 'playbookloader') || hasObjections(item)) && level < 4"
             class="mt-4 d-flex flex-column w-100"
        >
          <!-- Additional Playbooks -->
          <bao-item-list
            v-if="hasAdditionalPlaybooks"
            :label="staticText.addPlaybooksLabel"
            :items="item.additional_talkscripts"
            :playText="staticText.addPlaybookButtonText"
            icon="fas fa-book"
            play-icon="fas fa-plus"
            class="additional-playbooks"
            @input="additionalDataChoice => additionalDataSelected({additionalDataChoice, item})"
          ></bao-item-list>

          <!-- Shortcuts -->
          <bao-item-list
            v-if="hasObjections(item)"
            :label="staticText.shortcuts"
            :items="item.objections"
            :playText="staticText.addPlaybookButtonText"
            icon="fas fa-bolt"
            class="shortcuts"
            @input="objection => additionalDataSelected({objection, item}, true)"
          ></bao-item-list>
        </div>

        <!-- Notes for playbook Item-->
        <div role="group">
          <rich-text-editor v-if="item.note"
                            v-model="item.notes"
                            :placeholder="staticText.notesPlaceholder"
                            @input="onItemChange(item)"
          ></rich-text-editor>
        </div>

        <!-- CRM Task Creator -->
        <div v-if="isItemType(item,'datetimepicker')">
          <task-creator :call-item="item"
                        @notes-changed="changedNotes => handleNotesChanged(changedNotes, item)"
          ></task-creator>
        </div>

        <!-- CRM Event Creator -->
        <div v-if="isItemType(item,'crm_event') && canUseCrmEvent">
          <crm-event-creator :call-item="item"
                             @notes-changed="changedNotes => handleNotesChanged(changedNotes, item)"
          ></crm-event-creator>
        </div>

        <!-- CRM Phone Call Activity-->
        <div v-if="isItemType(item,'phone_call') && canUsePhoneCall">
          <phone-call-activity :call-item="item"
                               @notes-changed="changedNotes => handleNotesChanged(changedNotes, item)"
          ></phone-call-activity>
        </div>

        <!-- CRM Meeting Activity-->
        <div v-if="isItemType(item,'meeting') && canUseMeeting">
          <meeting-activity :call-item="item"
                            @notes-changed="changedNotes => handleNotesChanged(changedNotes, item)"
          ></meeting-activity>
        </div>

        <!-- Ending item -->
        <div v-if="item.ending_item"
             class="mt-3 px-2"
        >
          <call-view-end-button
            :is-open-from-call-item="true"
            @close-objection-modal="$emit('close-objection-modal')"
          ></call-view-end-button>
        </div>
      </b-collapse>
    </div>

    <!-- ADCs below parent -->
    <div
      v-if="loadedADCs(item)"
      class="mt-2"
    >
      <div :id="item.uniqueId + '-loadedData-' + index"
           v-for="(adc, index) in loadedADCs(item)"
           :key="item.uniqueId + '-loadedData-' + index"
           class="playbook-item mt-2"
      >
        <!-- ADC header -->
        <div class="d-flex align-items-center justify-content-between item-header"
             @click="toggleOpen(adc)"
        >
          <div>
            <i class="mr-1 fas"
               :class="getAdditionalDataChoiceIcon(adc)"
            ></i>
            <span :id="'call-objection-name-'+item.uniqueId + '-loadedData-' + index"
                  class="cardTitle"
            >
              {{ adc.selected_choice.name }}
            </span>
          </div>
          <div class="d-flex align-items-center">
            <bao-delete-button
              :id="'call-objection-delete-'+item.uniqueId + '-loadedData-' + index"
              variant="outline"
              size="sm"
              extra-class="d-flex align-items-center justify-content-center p-1 mr-2"
              @delete="deleteAdditionalData(adc.id, index)"
            >
              {{ staticText.warningModalText }}
            </bao-delete-button>

            <b-btn v-if="adc && adc.selected_choice"
                   variant="outline"
                   class="collapse-button d-flex align-items-center justify-content-center"
                   size="sm"
            >
              <div class="d-flex align-items-center">
                <span v-show="adc.hovered"
                      class="mr-2"
                >
                  {{ getAdditionalDataChoiceHelpText(adc) }}
                </span>

                <i v-if="!adc.open"
                   class="fas fa-chevron-down"
                ></i>

                <i v-else
                   class="fas fa-chevron-up"
                ></i>
              </div>
            </b-btn>
          </div>
        </div>

        <!-- ADC content/items -->
        <b-collapse :id="'playbook-' + adc.selected_choice.id + '-outside-collapse'"
                    v-if="adc.selected_choice"
                    v-model="adc.open"
                    class="px-3 pb-2"
                    visible
        >
          <!-- Items -->
          <div v-if="hasChildren(adc)"
               class="w-100"
          >
            <div :id="adc.selected_choice.id + '-outside-collapse-' + index"
                 v-for="(currentItem, index) in getChildren(adc)"
                 :key="index"
                 class="w-100"
            >
              <!-- The parent container (main-container) is passed recursively to the children of the item -->
              <call-view-item :item="currentItem"
                              :call-view-item-index="index"
                              :main-container="adc.type === 'objection' ? mainContainer : adc.selected_choice.workflow"
                              :playbook-id="adc.selected_choice.talkscript"
                              :additional-data="adc"
                              :level="level + 1"
                              :full-text-view="fullTextView"
                              :all-items-expanded="allItemsExpanded"
                              :clearCrmDataCount="clearCrmDataCount"
                              :downloadCrmDataCount="downloadCrmDataCount"
                              class="adc"
              ></call-view-item>
            </div>
          </div>

          <div v-else
               class="w-100 d-flex justify-content-between"
          >
            <progress-indicator></progress-indicator>
          </div>
        </b-collapse>
      </div>
    </div>
  </div>
</template>

<script>

import { utils } from "./index"
import axios from "axios"
import { mapGetters } from "vuex"
import { currentCallStore } from "@/store/services/callStore"
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css"
import BaseAlert from "@/apps/base/BaseAlert"
import { injectDynamicPlaceholderValues } from "@/apps/call/dynamicDataParser"
import PhoneCallActivity from "@/apps/call/CallViewItem_components/PhoneCallActivity"
import CallItemMixin from "./CallItemMixin"
import MeetingActivity from "@/apps/call/CallViewItem_components/MeetingActivity"

export default {
  name: "CallViewItem",
  mixins: [CallItemMixin],
  components: {
    PhoneCallActivity,
    MeetingActivity,
    BaseAlert,
    ProgressIndicator: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "ProgressIndicator" */ "../base/ProgressIndicator.vue"),
    RichTextEditor: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "RichTextEditor" */ "../richtexteditor/RichTextEditor.vue"),
    CallViewEndButton: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "CallViewEndButton" */ "./CallViewEndButton.vue"),
    TaskCreator: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "TaskCreator" */ "./CallViewItem_components/TaskCreator.vue"),
    CrmEventCreator: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "CrmEventCreator" */ "./CallViewItem_components/CrmEventCreator.vue"),
    BaoItemList: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "BaoItemList" */ "../base/BaoItemList.vue"),
    CrmFieldList: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "CrmField" */ "./CallViewItem_components/CrmFieldList.vue"),
    AnswerChoices: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "AnswerChoices" */ "./CallViewItem_components/AnswerChoices.vue"),
    BaoDeleteButton: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "BaoDeleteButton" */ "@/apps/base/BaoDeleteButton.vue"),
    VueCtkDateTimePicker: () => import(/* webpackPrefetch: true */ /* webpackChunkName: "VueCtkDateTimePicker" */ "vue-ctk-date-time-picker")
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    callViewItemIndex: {
      type: Number,
      required: true
    },
    playbookId: {
      required: true
    },
    fullTextView: {
      type: Boolean,
      default: true
    },
    allItemsExpanded: {
      type: Boolean,
      default: true
    },
    additionalData: {
      type: Object,
      default: null,
      required: false
    },
    mainContainer: {
      type: Object,
      required: false,
      default: () => {
        return null
      }
    },
    level: {
      type: Number,
      default: 0,
      required: false
    },
    clearCrmDataCount: {
      type: Number,
      default: 0
    },
    downloadCrmDataCount: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      utils,
      axios,
      staticTextDefault: {
        notesPlaceholder: "Enter your notes",
        selectDateTime: "Select a date and time",
        clickToCollapse: "(Click to collapse)",
        clickToExpand: "(Click to expand)",
        addPlaybooksLabel: "Add Playbook",
        addPlaybookButtonText: "Add",
        shortcuts: "Shortcuts",
        warningModalText: "Are you sure you want to delete this including all connected data? This is an irreversible process, you will not be able to undo.",
        unansweredRequiredItemAlert: "To end the conversation first answer all required items",
        datetimeLabel: "Select datetime",
        showFullTextLabel: "Show Text",
        hideFullTextLabel: "Hide Text"
      },
      selectedDatetime: null,
      localLoadedData: [],
      setupComplete: false,
      showItemFullText: false
    }
  },
  watch: {
    "item.loadedData": {
      deep: true,
      handler (val, oldVal) {
        // Note: this fixes a reactively issues with loaded objections,
        // where loaded objections can only be expanded/collapsed if we have a watcher?
        // TODO: fix reactively issues by properly setting variables!
        this.localLoadedData = val
      }
    },
    isFirstUnansweredRequiredItem (val) {
      if (val) {
        this.scrollTo(`#${this.item.uniqueId}`)
        setTimeout(() => {
          // The timeout is an estimate of how long it would take the scroll to be completed.
          // The item is activated just after the completion of the scroll
          this.activateItem(this.item)
        }, 800)
      }
    },
    allItemsExpanded (value) {
      this.toggleExpandOrCollapseItemAndLoadedADCs(value)
      // After the items are expanded/collapsed, it is very likely that the active item is scrolled out of
      // view. That's why this is important. And for the timeout, it's a bit tricky to estimate how much
      // time all the call items would be expanded/collapsed as an item can have as many loadedADCs as possible
      if (this.isActiveItem(this.item)) {
        setTimeout(() => {
          this.scrollTo(`#${this.item.uniqueId}`)
        }, 500)
      }
    },
    fullTextView () {
      this.showItemFullText = this.fullTextView
    }
  },
  mounted () {
    this.showItemFullText = this.fullTextView
  },
  computed: {
    ...mapGetters({
      canUseCrmEvent: "auth/canUseCrmEvent",
      canUsePhoneCall: "auth/canUsePhoneCall",
      canUseMeeting: "auth/canUseMeeting"
    }),
    unansweredRequiredItems () {
      return currentCallStore.unansweredRequiredItems
    },
    isCallActive () {
      return currentCallStore.isCallActive
    },
    getItemNameClass () {
      return this.isActiveItem(this.item) ? "playbook-item-name-active" : "playbook-item-name"
    },
    getIconForItemOpen () {
      return this.item.open ? "fas fa-chevron-up" : "fas fa-chevron-down"
    },
    hasAdditionalPlaybooks () {
      return this.item.additional_talkscripts && this.item.additional_talkscripts.length > 0
    },
    getPlaybookItemClass () {
      let itemContainerClass = "playbook-item " // "component-container "
      itemContainerClass += this.isActiveItem(this.item) ? "active " : ""
      if (this.unansweredRequiredItems.includes(this.item.uniqueId)) {
        itemContainerClass += "error-border"
      }
      return itemContainerClass
    },
    unansweredRequiredItemsExist () {
      return this.unansweredRequiredItems.length > 0 && this.unansweredRequiredItems.includes(this.item.uniqueId)
    },
    isFirstUnansweredRequiredItem () {
      return this.unansweredRequiredItems.length > 0 && this.unansweredRequiredItems[0] === this.item.uniqueId
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    showOrHideText () {
      return this.showItemFullText ? this.staticText.hideFullTextLabel : this.staticText.showFullTextLabel
    },
    shouldHideItem () {
      // hides playbook item of type CRM-Activity if related CRM is not integrated even if the item was configured in playbook.
      return (
        (this.isItemType(this.item, "crm_event") && !this.canUseCrmEvent) ||
        (this.isItemType(this.item, "phone_call") && !this.canUsePhoneCall) ||
        (this.isItemType(this.item, "meeting") && !this.canUseMeeting)
      )
    }
  },
  created () {
    // without waiting for the nextTick the rich text editor is throwing an
    // exception
    // TODO: check if we really need this, on the other side it doesnt really
    //  slow down anything I feel like
    this.transformItem(this.item)
  },
  methods: {
    activateItem (item) {
      if (!this.isActiveItem(item)) {
        currentCallStore.activateItem(item)
      }
    },
    onItemChange (item) {
      currentCallStore.handleItemChanged(item)
    },
    handleResetUnansweredRequiredItems () {
      currentCallStore.handleResetUnansweredRequiredItems()
    },
    isActiveItem (item) {
      return currentCallStore.activeItem === item
    },
    isItemAnswered (item) {
      return currentCallStore.isItemAnswered(item)
    },
    scrollTo (selector) {
      return currentCallStore.scrollTo(selector)
    },
    additionalDataSelected (payload, isObjection = false) {
      return currentCallStore.additionalDataSelected({ payload, isObjection })
    },
    transformItem (item) {
      item.uniqueId = utils.getUniqueId()
      const open = this.isCallActive ? !item.collapse : true
      this.$set(item, "open", open)
      item.playbookId = this.playbookId
      if (item.call_item) {
        item.callItem = item.call_item
        item.notes = item.call_item.note
        item.duration = item.call_item.duration
        // edited field is used in navigation bar to show the item is edited
        this.$set(item, "edited", this.isItemAnswered(item))
      }
      this.getSelectedDatetime(item)

      // the first time additionalData will be the AditionalDataChoice while the second time it will be a combination
      // of the AdditionalData object and the AdditionalDataChoice, thus we only update the item when we actually should
      if (this.additionalData && this.additionalData.call) item.additional_data = this.additionalData.id

      // TODO: refactor/optimize this!
      setTimeout(
        () => {
          this.setupComplete = true
        },
        Math.floor(this.callViewItemIndex / 4) * 150
      )
    },
    getAdditionalDataChoiceIcon (adc) {
      return this.getAdditionalDataChoiceType(adc) === "default" ? "fa-book" : "fa-bolt color-orange"
    },
    getAdditionalDataChoiceHelpText (adc) {
      return adc.open ? this.staticText.clickToCollapse : this.staticText.clickToExpand
    },
    handleNotesChanged (notes, item) {
      this.$set(item, "notes", notes)
      this.onItemChange(item)
    },
    getSelectedDatetime (item) {
      if (item.callItem && item.item_type === "datetime_select" && item.callItem.answers.length > 0) {
        this.selectedDatetime = item.callItem.answers[0].text
      }
    },
    setSelectedDatetime (selectedDatetime, item) {
      const selectedAnswers = selectedDatetime ? [{ text: selectedDatetime }] : []
      this.handleAnswersChanged(selectedAnswers, item)
    },
    handleAnswersChanged (selectedAnswers, item) {
      this.$set(item, "selectedAnswers", selectedAnswers)
      this.onItemChange(item)
    },
    deleteAdditionalData (additionalDataId, index) {
      const item = this.item
      currentCallStore.deleteAdditionalData(additionalDataId).then(response => {
        item.loadedData.splice(index, 1)
        for (const talkscript of item.additional_talkscripts) {
          if (talkscript.loadedData && talkscript.loadedData.additional_data.id === additionalDataId) {
            this.$set(talkscript, "loadedData", null)
          }
        }
      })
    },
    getAdditionalDataChoiceType (item) {
      if (item && item.selected_choice) {
        return item.selected_choice.type
      }
      return null
    },
    toggleOpen (item) {
      item.open = !item.open
    },
    toggleShowOrHideText () {
      this.showItemFullText = !this.showItemFullText
    },
    injectPlaceholderValues (text) {
      return injectDynamicPlaceholderValues(text)
    },
    replaceParagraphWithLineBreaks (text) {
      const modifiedHtml = this.injectPlaceholderValues(text)
      return modifiedHtml.replace(/<p[^>]*>/g, "").replace(/<\/p>/g, "<br/>")
    },
    toggleExpandOrCollapseItemAndLoadedADCs (value) {
      this.$set(this.item, "open", value)
      const loadedADCs = this.loadedADCs(this.item)
      if (loadedADCs) {
        for (const adc of loadedADCs) {
          adc.open = value
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.item-header {
  cursor: pointer;
  padding: 16px;
}

.item-content-container {
  padding-bottom: 20px;
}

.color-orange {
  color: $orange;
}

.playbook-item {
  margin-bottom: 8px;
  color: $slate80;
  border-radius: 12px;
  background-color: $white;
  box-shadow: 4.23529px 5.64706px 14.1176px 3.52941px rgba(0, 0, 0, 0.03);
  transition: 0.3s ease-in;
  border: 1px solid transparent;

  &.active {
    border-color: rgba(93, 81, 75, 0.8);
  }

  &__display_text {
    background: rgba(42, 45, 82, 0.03);
    border-radius: 8px;
    color: #2A2D52;
    padding: 14px 16px;
    margin-top: 8px;
  }
}

.adc > .playbook-item {
  background-color: $white80;
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
}

.error-border {
  border: 1px solid #bc0000 !important;
}

.collapse-button, .collapse-button:focus {
  outline: none;
  padding: 0;
}

.w-content {
  width: fit-content;
}

.show_hide_text {
  display: flex;
  align-items: center;
  width: fit-content;
  font-size: 12px;
  line-height: 14px;
  color: $slate80;
  margin-top: 3px;

  span {
    margin-top: 3px;
  }

  svg {
    height: 3.6px;
    width: 7.2px;
    margin-left: 5.4px;
    transition: 0.3s ease-in;

    &.hidden {
      transform: rotate(180deg);
    }
  }
}

.alert__message {
  margin: 8px 16px -10px 16px;
}

.additional-playbooks,
.shortcuts {
  width: 100%;
  margin-bottom: 8px;
  @media (min-width: 992px) {
    width: 70%
  }
  @media (min-width: 1250px) {
    width: 50%
  }
}

</style>

<style lang="scss">
.playbook-item__display_text {
  overflow-wrap: anywhere;

  p, h1, h2, h3, h4, h5, h6 {
    margin: 0 !important;
  }
}
</style>
