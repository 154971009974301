<template>
  <div class="shortcuts shortcuts__container">
    <div id="shortcut-header-202101061420"
         class="shortcuts__header"
    >
      <div class="d-flex align-items-center">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'shortcut-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.273 8.418H10.91l3.768-6.629A.528.528 0 0014.219 1H7.89a.527.527 0 00-.5.36l-3.164 9.528a.527.527 0 00.5.694H9.2l-2.849 6.683a.527.527 0 00.879.558l8.437-9.527a.527.527 0 00-.394-.878z" fill="#2B2727" opacity=".8"/></svg>
        <h1 class="title mb-0">{{ staticText.shortcuts }}</h1>
      </div>
      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:id="'shortcuts-description-info-icon'" v-bind:class="'info-icon'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M0 9c0-4.963 4.037-9 9-9s9 4.037 9 9-4.037 9-9 9-9-4.037-9-9zm8.167-3.674l-.01-.01a.543.543 0 01-.129-.362v-.778c0-.135.042-.26.13-.362l.004-.005.005-.005a.513.513 0 01.372-.154h.907c.144 0 .271.053.372.154a.513.513 0 01.154.372v.778c0 .143-.052.27-.154.372l-.004.005-.006.004a.543.543 0 01-.362.13H8.54a.543.543 0 01-.362-.13l-.01-.009zm.115 8.885l-.01-.01a.543.543 0 01-.129-.363V7.013a.49.49 0 01.14-.358.513.513 0 01.371-.153h.692c.139 0 .264.05.358.153a.468.468 0 01.153.358v6.825c0 .144-.052.271-.154.373a.49.49 0 01-.357.139h-.692a.543.543 0 01-.362-.13l-.01-.01z" fill="#35375B" opacity=".6"/></svg>
      <b-tooltip
        :title="staticText.shortcutsDescriptionInfo"
        target="shortcuts-description-info-icon"
        placement="bottomleft"
      ></b-tooltip>
    </div>

    <div
      v-if="shortcuts.length > 0"
      id="shortcut-list-202103011138"
      class="shortcuts__list"
    >
      <div
        :id="'shortcut-btn-' + index"
        v-for="(shortcut, index) in shortcuts"
        :key="'shortcut-btn-' + index"
        class="shortcuts__item"
        :class="{ 'active': activeShortcut && activeShortcut.id === shortcut.id }"
        @click="handleSelection(shortcut)"
      >
        {{ shortcut.name }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapStateToCurrentCallStore } from "@/apps/call/utils"

export default {
  name: "ShortCuts",
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      shortcuts: [],
      staticTextDefault: {
        shortcuts: "Shortcuts",
        shortcutsDescriptionInfo: "Here you see all your shortcuts to frequently raised objections, frequently asked questions and similar."
      }
    }
  },
  computed: {
    ...mapStateToCurrentCallStore([
      "call",
      "activeItem",
      "activeShortcut"
    ]),
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    shortcuts (val) {
      this.$emit("input", val)
    },
    item (val) {
      this.shortcuts = val.objections
    }
  },
  created () {
    this.shortcuts = this.item.objections
  },
  methods: {
    handleSelection (shortcut) {
      this.$emit("shortcutSelected", shortcut)
    }
  }
}
</script>

<style scoped lang="scss">

.shortcuts {
  &__container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    background: $white;
    border-radius: 12px;
    padding: 16px;
    overflow: hidden;
  }
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 20px;
    z-index: 9999999;
    @include media-breakpoint-down(sm) {
      display: none;
    }
    .title {
      font-weight: 700;
      font-size: 16px;
      line-height: 19px;
      color: $slate;
      margin-left: 4px;
    }

    .shortcut-icon {
      path {
        fill: $slate60;
      }
    }
    .info-icon {
      cursor: pointer;
    }
  }
  &__list {
    height: calc(100% - 32px);
    overflow-y: auto;
    padding-right: 8px;
    @include media-breakpoint-up(md) {
      margin-top: 12px;
    }
  }
  &__item {
    padding: 8px 12px;
    max-width: 100%;
    width: fit-content;
    word-break: break-all;
    cursor: pointer;
    background-color: $white80;
    border: 1px solid $slate20;
    border-radius: 12px;
    margin-bottom: 8px;
    transition: 0.3s ease-in;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: $slate;
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      background-color: $slate06;
    }
    &.active {
      background-color: $slate80;
      border-color: transparent;
      color: $white;
    }
  }
}

</style>
