<template>
  <div class="main-container px-md-2">
    <div v-if="canAddBotToMeeting">
      <b-dropdown ref="connectBotRef"
                  no-caret class="google-meet-menu ml-2"
                  offset="-108 16"
      >
        <template slot="button-content">

          <div class="meet-container d-flex align-items-center">
            <img src="@/assets/svgs/video-meet.svg">
            <span class="ml-1 connection-text py-3">Video Meet</span>
          </div>
        </template>
        <template slot="default">
          <div class="connect-menu py-2" >
            <div class="connection-message">
              <span class="mr-1">{{ staticText.connectionMsg }}</span>
              <img src="@/assets/svgs/google-meet.svg" svg-inline>
              <span class="mx-1">Meet</span>
              <span class="mr-1">{{staticText.orLabel}}</span>
              <img src="@/assets/svgs/ms-teams.svg" svg-inline>
              <span class="ml-1">Teams</span>
            </div>
            <b-form-input
              v-model="meetingUrl"
              required
              :placeholder="staticText.meetingUrlPlaceHolder"
              autocomplete="off"
              class="enter-url"
            >
            </b-form-input>
            <div class="d-flex mt-3">
              <b-form-checkbox
                v-model="recordingConsent"
                size="20px"
                class="custom-checkbox"
              >
              </b-form-checkbox>
              <div class="recording-consent ml-2">
                {{staticText.recordingConsentLabel}}
              </div>
            </div>
            <div id="connect-bot-202406120604">
              <button
                class="btn btn-primary connect-btn mt-3 w-100"
                @click="connectBotToMeeting"
                :disabled="!enableConnectBot"
              >
                {{ staticText.connectLabel }}
              </button></div>
            <b-tooltip
              v-if="!enableConnectBot"
              target="connect-bot-202406120604" triggers="hover"
              custom-class="custom-tooltip"
            >
              <div class="d-flex align-items-center">
                {{staticText.connectBtnTooltip}}
              </div>
            </b-tooltip>
          </div>
        </template>
      </b-dropdown>
    </div>
    <div
      v-else-if="meetingBotStatus==MeetingBotStatusEnum.CONNECTING"
      class="d-flex align-items-center connecting-status"
    >
      <b-spinner
        id="redirecting-spinner-202101081826"
        class="my-auto"
        small
        variant="success"
      ></b-spinner>
      <span class="ml-2 connecting-text">{{staticText.connectingLabel}}</span>
      <div v-if="!!isBotActiveForMeeting">
        <remove-bot
          :removingBot="isBotDisConnecting"
          @remove-bot-from-meeting="removeBotFromMeeting"
        />
      </div>
    </div>
    <div
      v-else-if="meetingBotStatus==MeetingBotStatusEnum.CONNECTED"
      class="d-flex"
    >
      <div
        class="d-flex align-items-baseline connected-bot py-2 px-2"
        id="meeting-bot-202405151829"
      >
        <div class="meeting-bot-connection">
          <img src="@/assets/svgs/google-meet.svg">
          <div v-if="isBotRecording" class="bot-recording">
            <img src="@/assets/svgs/meeting_bot_connected.svg">
          </div>
        </div>
        <span class="ml-1 meeting-status d-flex align-items-center">{{staticText.connectedLabel}}</span>
      </div>
      <b-tooltip target="meeting-bot-202405151829" triggers="hover"
                 custom-class="custom-tooltip"
      >
        <div class="d-flex align-items-center">
          <div
            :class="['recording-control-btn', {'cursor-pointer': botRecordingStatus!=BotRecordingStatusEnum.TOGGLING}]"
            @click="toggleMeetingBotRecording"
          >
            <b-spinner
              v-if="botRecordingStatus==BotRecordingStatusEnum.TOGGLING"
              small></b-spinner>
            <i
              v-else
              :class="{'fas fa-pause': isBotRecording, 'fas fa-play': !isBotRecording}"
              :style="{ width: '16px', height: '16px' }"
            ></i>
          </div>
          <div class="ml-2 mr-2 recording-text">{{recordingStatusLabel}}</div>
        </div>
      </b-tooltip>

      <div
        v-if="!!isBotActiveForMeeting"
        class="d-flex"
      >
        <remove-bot
          :removingBot="isBotDisConnecting"
          @remove-bot-from-meeting="removeBotFromMeeting"
        />
      </div>
    </div>
  </div>

</template>

<script>

import { mapGetters, mapActions } from "vuex"
import RemoveBot from "@/apps/call/meeting/RemoveBot"
import { MeetingBotStatusEnum, BotRecordingStatusEnum } from "@/apps/call/constants"
import { checkAndUpdateScheduledBot } from "@/apps/call/api"

export default ({
  name: "ConnectBot",
  components: {
    RemoveBot
  },
  data () {
    return {
      recordingConsent: false,
      staticTextDefault: {
        connectLabel: "connect",
        connectingLabel: "connecting …",
        connectedLabel: "connected",
        recordingLabel: "recording",
        notRecordingLabel: "on pause",
        meetingUrlPlaceHolder: "Enter URL",
        recordingConsentLabel: "I have my participants’ consent to record the meeting.",
        connectionMsg: "Enter URL for",
        orLabel: "or",
        connectBtnTooltip: "Please enter meeting URL and accept recording consent to connect the bot."
      },
      meetingUrl: null,
      togglingBotStatus: false,
      MeetingBotStatusEnum,
      BotRecordingStatusEnum
    }
  },
  mounted () {
    this.resetCallVideoStore()
    if (!this.connectMeetingFromUrl()) {
      this.updateMeetingBot()
    }
  },
  methods: {
    ...mapActions({
      toggleMeetingRecording: "callVideoStore/toggleMeetingRecording",
      fetchMeetingBotStatus: "callVideoStore/fetchMeetingBotStatus",
      createMeetingBot: "callVideoStore/createMeetingBot",
      removeBotFromMeeting: "callVideoStore/removeBotFromMeeting",
      fetchCallMeetingData: "callVideoStore/fetchCallMeetingData",
      resetCallVideoStore: "callVideoStore/resetCallVideoStore",
      setBotRecordingStatus: "callVideoStore/setBotRecordingStatus"
    }),
    connectMeetingFromUrl () {
      this.meetingUrl = this.$route.query.meetingUrl || null
      if (this.meetingUrl) {
        const query = Object.assign({}, this.$route.query)
        delete query.meetingUrl
        this.$router.replace({ query })
        this.recordingConsent = true
        this.connectBotToMeeting()
      }
      return this.meetingUrl
    },
    toggleMeetingBotRecording () {
      return this.toggleMeetingRecording()
    },
    showWarningMessage (title, errorMsg) {
      this.showGlobalToast({
        status: "error",
        message: { title, description: errorMsg }
      })
    },
    async updateMeetingBot () {
      await this.fetchCallMeetingData({ callId: this.callId })
      if (this.isBotActiveForMeeting) {
        const apiCallCount = 30 // max number of times the api to be called
        const apiCallInterval = 4 // interval in second

        await this.watchBotConnection(apiCallCount, apiCallInterval)
        if (this.meetingBotStatus === MeetingBotStatusEnum.CONNECTED) {
          this.setBotRecordingStatus({ botRecordingStatus: BotRecordingStatusEnum.RECORDING })
        }
      }
    },
    async watchBotConnection (apiCallCount, apiCallInterval) {
      // watch for connection to know the status of bot
      let idx = 0
      for (idx = 0; idx < apiCallCount; idx++) {
        await this.sleep(apiCallInterval * 1000)
        if (!this.isBotActiveForMeeting) {
          break
        }
        await this.fetchMeetingBotStatus()
        if (!this.isBotActiveForMeeting) {
          break
        }
        if (this.meetingBotStatus === MeetingBotStatusEnum.CONNECTED) {
          break
        }
      }
      // if the bot doesn't connect in the given time then change status to not_started
      // remove bot from meeting
      if (this.meetingBotStatus !== MeetingBotStatusEnum.CONNECTED && this.isBotActiveForMeeting) {
        await this.removeBotFromMeeting()
        this.showWarningMessage("Max Time exceeded", "Couldn't connect the bot to the meeting. Please try again")
      }
    },
    async connectBotToMeeting () {
      if (!this.enableConnectBot) {
        return
      }
      // reset video store before connecting new bot
      this.resetCallVideoStore()
      const { data } = await checkAndUpdateScheduledBot({ call_id: this.callId, meeting_link: this.meetingUrl })
      if (!!data && !!data.ongoing_meeting) {
        this.updateMeetingBot()
        return
      }
      this.$refs.connectBotRef.hide()
      await this.createMeetingBot({ meetingUrl: this.meetingUrl, callId: this.callId })
      // reset meeting url and recording consent
      this.meetingUrl = null
      this.recordingConsent = false
      // start fetching the bot status after some interval to know the status of bot
      const apiCallCount = 30 // max number of times the api to be called
      const apiCallInterval = 4 // interval in second
      await this.watchBotConnection(apiCallCount, apiCallInterval)
      if (this.meetingBotStatus === MeetingBotStatusEnum.CONNECTED) {
        this.setBotRecordingStatus({ botRecordingStatus: BotRecordingStatusEnum.RECORDING })
      }
    },
    autoConnectMeetingBot (meetingUrl) {
      this.recordingConsent = true
      this.meetingUrl = meetingUrl
      this.connectBotToMeeting()
    }
  },
  computed: {
    ...mapGetters({
      isBotRecording: "callVideoStore/isBotRecording",
      botRecordingStatus: "callVideoStore/getBotRecordingStatus",
      isBotActiveForMeeting: "callVideoStore/isBotActiveForMeeting",
      isBotDisConnecting: "callVideoStore/isBotDisconnecting",
      meetingBotStatus: "callVideoStore/getMeetingBotStatus"
    }),
    enableConnectBot () {
      return !!this.recordingConsent && !!this.meetingUrl
    },
    canAddBotToMeeting () {
      return [MeetingBotStatusEnum.NOT_STARTED, MeetingBotStatusEnum.ENDED, MeetingBotStatusEnum.DONE].includes(this.meetingBotStatus)
    },
    recordingStatusLabel () {
      return this.isBotRecording ? this.staticText.recordingLabel : this.staticText.notRecordingLabel
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    },
    callId () {
      return this.$route.params.callId ? this.$route.params.callId : null
    }
  }
})
</script>

<style lang="scss" scoped>

.recording-control-btn{
  background-color: #F0F0F0;
  color: #555775;
  border-radius: 8px 0 0 8px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

.meeting-bot-connection{
  position: relative;
}

.bot-recording{
  position: absolute;
  top: -8px;
  right: -2px;

}
.google-meet-menu {
  border-radius: 12px;
  z-index: 9999999999;
  :deep(.btn) {
    padding: 0 !important;
  }
  :deep(.dropdown-menu){
    z-index: 10000000
  }
}

.meeting-status{
  color: #AAABBA !important;
  font-size: 12px !important;
  line-height: 15px !important;
}
.meet-container{
  background-color: #F0F0F0 !important;
  border-radius: 12px;
  padding: 0 10px;

}
.connection-text{
  font-size: 12px !important;
  line-height: 14px !important;
  font-weight: 400 !important;
  color: #555775 !important
}
.connect-menu {
  width: 324px !important;
  padding: 8px 16px 8px 16px !important;
}
.connect-btn{
  height: 46px !important;
}

.dropdown-menu{
  z-index: 10000000;
}

.connected-bot{
  background-color: #E9F7E6;
  border-radius: 12px;
}

.connecting-text{
  font-size: 12px;
  color: #555775;
}

.custom-tooltip{
  :deep(.tooltip-inner){
    padding: 0 !important;
  }
  :deep(.tooltip .tooltip-inner){
    padding: 0 !important;
  }
}

.main-container{
  border-right: 1px solid $slate20;
  @include media-breakpoint-down(md) {
    border-right: none;
    border-left: 1px solid $slate20;
    margin-left: 8px;
  }
  @include media-breakpoint-down(sm) {
    border: none;
  }
}

.recording-text{
  color: #7F8197;
  font-size: 12px;
}

.recording-consent{
  font-size: 12px;
  color: #333333;
}

.connection-message {
  display: flex;
  align-items: center;
  font-size: 15px;
  margin-bottom: 16px;
}

.enter-url{
  box-shadow: 4px 4px 20px 0 rgba(0, 0, 0, 0.05);
}

.connecting-status{
  white-space: nowrap;
}

</style>
