import axios from "axios"
import { getCallIdFromURL } from "@/utils/utils"
import { getUserMediaStream } from "@/utils/microphone"
import { currentCallStore } from "@/store/services/callStore"

const HEADERS = { "Content-Type": "application/json" }
const CHUNK_SIZE = 5500000 // ~5.5MB used for S3 upload
const MEDIA_RECORDER_OPTIONS = { mimeType: "audio/webm" }
const MEDIA_RECORDER_TIMESLICE_FOR_RECORDING = 1000

class AudioRecorder {
  constructor () {
    this.mediaRecorder = null
    this.uploadId = null
    this.callId = null
    this.parts = []
    this.audioChunks = []
    this.globalPartNumber = 1
  }

  async startRecording () {
    await this.resetState()
    this.callId = await getCallIdFromURL()
    const stream = await getUserMediaStream()
    this.mediaRecorder = new MediaRecorder(stream, {
      mimeType: "audio/webm"
    })
    await this.createMultipartUpload()
    this.mediaRecorder.ondataavailable = async (event) => {
      if (event.data.size > 0) {
        this.audioChunks.push(event.data)
        const blob = new Blob(this.audioChunks, { type: "audio/webm" })
        if (blob.size >= CHUNK_SIZE) {
          this.audioChunks = []
          const partNumber = this.globalPartNumber++
          await this.uploadChunkToS3(blob, partNumber)
        }
      }
    }

    this.mediaRecorder.start(MEDIA_RECORDER_TIMESLICE_FOR_RECORDING)
    currentCallStore.sendMessageToBaoSwift({
      type: "callRecordingStarted"
    })
  }

  async stopRecording () {
    await this.resetMediaRecorder()

    if (this.audioChunks.length > 0) {
      const blob = new Blob(this.audioChunks, { type: "audio/webm" })
      if (blob.size > 0) {
        const partNumber = this.globalPartNumber
        await this.uploadChunkToS3(blob, partNumber)
      }
    }

    await this.completeMultipartUpload()
    await this.resetState()
  }

  async resetMediaRecorder () {
    if (this.mediaRecorder && this.mediaRecorder.state !== "inactive") {
      this.mediaRecorder.stop()
      await new Promise((resolve) => {
        this.mediaRecorder.onstop = () => {
          if (this.mediaRecorder.stream) {
            this.mediaRecorder.stream.getTracks().forEach((track) => track.stop())
          }
          resolve()
        }
      })
    }
    if (this.mediaRecorder) {
      this.mediaRecorder.ondataavailable = null
      this.mediaRecorder.onstop = null
      this.mediaRecorder = null
    }
  }

  async resetState () {
    await this.resetMediaRecorder()
    this.uploadId = null
    this.callId = null
    this.parts = []
    this.audioChunks = []
    this.globalPartNumber = 1
  }

  async makeApiCall (url, data) {
    try {
      const response = await axios.post(url, JSON.stringify(data), {
        headers: HEADERS
      })
      return response.data
    } catch (error) {
      console.log(`AR: Error making API call to ${url}:`, error)
      throw error
    }
  }

  async createMultipartUpload () {
    const response = await this.makeApiCall(
      "/api/callAudioData/initiate_multipart_upload",
      { call_id: this.callId }
    )
    this.uploadId = response.upload_id
  }

  async getPresignedUrl (partNumber) {
    const response = await this.makeApiCall(
      "/api/callAudioData/generate_presigned_url",
      {
        call_id: this.callId,
        part_number: partNumber,
        upload_id: this.uploadId
      }
    )
    return response.url
  }

  async savePartsToBackend () {
    const sortedParts = this.parts.sort((a, b) => a.PartNumber - b.PartNumber)
    try {
      await this.makeApiCall("/api/callAudioData/save_parts", {
        call_id: this.callId,
        upload_id: this.uploadId,
        parts: sortedParts
      })
      console.log("AR: Successfully saved parts to backend")
    } catch (error) {
      console.error("AR: Error saving parts to backend:", error)
    }
  }

  async uploadChunkToS3 (chunk, partNumber, maxRetries = 3) {
    for (let attempt = 1; attempt <= maxRetries; attempt++) {
      try {
        const presignedUrl = await this.getPresignedUrl(partNumber)
        const response = await axios.put(presignedUrl, chunk, {
          headers: { "Content-Type": MEDIA_RECORDER_OPTIONS.mimeType }
        })
        this.parts.push({
          ETag: response.headers.etag.replace(/"/g, ""),
          PartNumber: partNumber
        })
        console.log(`AR: Uploaded part ${partNumber} with size: ${chunk.size} bytes`)
        await this.savePartsToBackend()
        return
      } catch (error) {
        console.log(`AR: Upload attempt ${attempt} for part ${partNumber} failed:`, error)
        if (attempt === maxRetries) throw error
      }
    }
  }

  async completeMultipartUpload () {
    if (this.parts.length === 0) {
      console.log("AR: No parts to upload, skipping completion.")
      return
    }

    this.parts.sort((a, b) => a.PartNumber - b.PartNumber)
    await this.makeApiCall("/api/callAudioData/complete_multipart_upload", {
      call_id: this.callId,
      upload_id: this.uploadId,
      parts: this.parts
    })
  }
}

export default AudioRecorder
