const IntegrationStatusEnum = Object.freeze({
    CONNECTED: "Connected",
    NOT_CONNECTED: "Not Connected"
})

const CalendarTypeEnum = Object.freeze({
    GOOGLE: 'google',
    OUTLOOK: 'outlook'
})

const Calendar_Items = {
    GOOGLE: {
        name: "Google Calendar",
        key: CalendarTypeEnum.GOOGLE,
        icon: "google-calendar"
    },
    OUTLOOK: {
        name: "Outlook Calendar",
        key: CalendarTypeEnum.OUTLOOK,
        icon: "outlook-calendar"
    }
};

export { IntegrationStatusEnum, Calendar_Items, CalendarTypeEnum }