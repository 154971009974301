const CounterpartDataMixin = {
  name: "CounterpartDataMixin",
  data () {
    return {
      staticTextDefault: {
        errorTitle: "Error",
        errorDescriptionMessage: "Counterpart data could not be loaded."
      }
    }
  },
  computed: {
    counterpartDataStaticText () {
      return this.$store.getters["I18nStore/getI18n"]("CounterpartDataMixin", this.staticTextDefault)
    }
  },
  methods: {
    showErrorToast () {
      const errorMessage = {
        title: this.counterpartDataStaticText.errorTitle,
        description: this.counterpartDataStaticText.errorDescriptionMessage
      }
      this.showGlobalToast({
        status: "error",
        message: errorMessage
      })
    }
  }
}

export default CounterpartDataMixin
