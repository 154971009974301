<template>
  <div
    id="speech-recognition-section"
    class="mb-2 select-interlocutor p-0 d-md-none d-lg-block user-select-none"
  >
    <div class="p-3">
      <div class="d-flex align-items-center justify-content-between">
        <div
          class="d-flex align-items-center cursor-pointer"
          @click="baoRealTimeAssistantExtended = !baoRealTimeAssistantExtended"
        >
          <!-- Speech Recognition toggle button -->
          <div class="mr-2" id="bao-toggle-btn-2193925">
            <div class="d-flex align-items-top">
              <div class="position-relative d-inline-block">
                <button
                  v-if="!isConversationEdited"
                  class="btn-speech-recognition"
                  @click.stop="handleSpeechBtnClick"
                >
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="recognitionRunning" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_16300_6740)" stroke="#f24a4a" stroke-linecap="round" stroke-linejoin="round"><path d="M10 .834a2.5 2.5 0 00-2.5 2.5v6.667a2.5 2.5 0 005 0V3.334a2.5 2.5 0 00-2.5-2.5z" fill="#f24a4a" stroke-width="2"/><path d="M15.833 8.334v1.667a5.833 5.833 0 01-11.667 0V8.334" stroke-width="2"/><path d="M10 15.834v3.333M6.666 19.166h6.667" stroke-width="1.667"/></g><defs><clipPath id="clip0_16300_6740"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_16300_6740)" stroke="#7F8197" stroke-linecap="round"><path d="M10 .834a2.5 2.5 0 00-2.5 2.5v6.667a2.5 2.5 0 005 0V3.334a2.5 2.5 0 00-2.5-2.5z" fill="#7F8197" stroke-width="2" stroke-linejoin="round"/><path d="M15.833 8.334v1.667a5.833 5.833 0 01-11.667 0V8.334" stroke-width="2" stroke-linejoin="round"/><path d="M10 15.834v3.333M6.666 19.166h6.667" stroke-width="1.667" stroke-linejoin="round"/><path d="M3 3l14 14" stroke-width="2"/></g><defs><clipPath id="clip0_16300_6740"><path fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs></svg>
                </button>
                <img v-if="!microphoneAccessGranted"
                     class="warning-icon"
                     width="12px"
                     src="@/assets/svgs/speechRecognition/warning-icon.svg"/>
              </div>
            </div>
            <b-tooltip
              target="bao-toggle-btn-2193925"
              placement="top"
              :boundary-padding="20"
              triggers="hover"
              :delay="{ show: 0, hide: 0 }"
              :title="
                microphoneAccessGranted ?
                  recognitionRunning
                    ? staticText.stopSpeechRecognition
                    : staticText.startSpeechRecognition :
                  staticText.noMicrophoneAccessToolTip
              "
            ></b-tooltip>
          </div>
          <div class="mr-2 speech-label">
            {{ staticText.SpeechRecognitionLabel }}
          </div>
          <div class="d-inline-flex align-items-center">
            <img
              id="bao-microphone-settings-btn-2193925"
              v-on="microphoneAccessGranted ? { click: handleMicrophoneSelectionClick } : {}"
              @click.stop
              class="pr-1 pl-2 cursor-pointer" src="/img/icons/settings-icon.svg" svg-inline/>
            <svg width="13" height="7" viewBox="0 0 13 7" fill="none" xmlns="http://www.w3.org/2000/svg" :class="[
                'chevron-up',
                { closed: !baoRealTimeAssistantExtended }
              ]" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.002 5.149a.847.847 0 00.456.753.86.86 0 00.946-.099l4.584-3.808 4.597 3.817a.857.857 0 001.206-.128.848.848 0 00-.128-1.24L6.527.193a.859.859 0 00-1.086 0L.309 4.443a.85.85 0 00-.307.705z" fill="#2A2D52" fill-opacity=".8"/></svg>
          </div>
          <b-tooltip
            v-if="!microphoneAccessGranted"
            target="bao-microphone-settings-btn-2193925"
            placement="bottom"
            :boundary-padding="20"
            triggers="hover"
            :delay="{ show: 0, hide: 0 }"
            :title="staticText.noMicrophoneAccessToolTip"
          ></b-tooltip>
        </div>

      </div>
    </div>
    <b-collapse v-model="baoRealTimeAssistantExtended">
      <real-time-assistant
        :call-talking-speed="callTalkingSpeed"
        :call-talking-share="callTalkingShare"
      ></real-time-assistant>
      <div v-if="!microphoneAccessGranted" class="inactive-microphone-container d-flex align-items-start p-3">
        <img
          width="20px"
          class="mr-2"
          src="@/assets/svgs/speechRecognition/warning-icon.svg"/>
        <div class="d-flex flex-column">
          <div class="speech-label" >{{ staticText.noMicrophoneAccessLabel }}</div>
          <div class="font-size-14 text-slate-80 pb-2" >{{ staticText.noMicrophoneAccessMessage }}</div>
          <button class="activate-microphone-button" v-b-modal.mic-access-modal>{{ staticText.microphoneActivateButtonLabel }}</button>
        </div>
      </div>
    </b-collapse>
    <div v-click-outside="handleMicrophoneSelectionClick" v-if="showMicrophoneSelection" class="microphone-selector-container d-flex flex-column align-items-start p-3">
      <div class="audio-input-label text-slate-40">{{staticText.audioInputLabel}}</div>
      <div class="d-flex flex-column">
        <!-- //Loop over audio devices and list their names: -->
        <div v-for="audioDevice in audioDevices" :key="audioDevice.deviceId" class="mb-2">
          <div :for="audioDevice.deviceId"
               class="p-2 rounded-2 hover-bg-light border-bottom d-flex justify-content-between align-items-center"
               @click.stop="selectPreferredMicrophone(audioDevice.deviceId)">
            <span>{{ audioDevice.label }}</span>
            <img v-if="preferredMicrophoneId === audioDevice.deviceId"
                 width="20"
                 src="@/assets/svgs/speechRecognition/selected-microphone-check.svg"
                 alt="Selected"
                 class="ms-2" />
          </div>
        </div>
      </div>
    </div>
    <b-modal id="mic-access-modal" size="lg" centered hide-footer>
      <template #modal-header-close>
        <svg width="16" height="16" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'ml-2 cursor-pointer'" @click="$bvModal.hide('mic-access-modal')" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7 .6C3.48.6.6 3.48.6 7c0 3.52 2.88 6.4 6.4 6.4 3.52 0 6.4-2.88 6.4-6.4C13.4 3.48 10.52.6 7 .6zm3.2 8.704l-.896.896L7 7.896 4.696 10.2 3.8 9.304 6.104 7 3.8 4.696l.896-.896L7 6.104 9.304 3.8l.896.896L7.896 7 10.2 9.304z" fill="#AAABBA"/></svg>
      </template>
      <div class="d-flex flex-row align-items-center">
        <img src="/img/promptGuides/mic-access-steps.png" />
        <div class="pl-2">
          <div class="pb-2 h4">{{ staticText.microphoneAccessModalTitle }}</div>
          <ol class="font-size-14 text-slate-80 pb-2">
            <li>{{ staticText.microphoneAccessModalStep1 }}</li>
            <li>{{ staticText.microphoneAccessModalStep2 }}</li>
          </ol>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import RealTimeAssistant from "../RealTimeAssistantComponents/RealTimeAssistant.vue"
import { currentCallStore } from "@/store/services/callStore"
import { realTimeAssistantService } from "../RealTimeAssistantComponents/index"
import _isEqual from "lodash/isEqual"
import { mapActions, mapMutations } from "vuex"
import { setCookie, getCookie } from "@/utils/utils"
import { listValidAudioDevices } from "@/utils/microphone"
import vClickOutside from "v-click-outside"

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition
const recognition = SpeechRecognition ? new SpeechRecognition() : false

export default {
  name: "SpeechRecognition",
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    language: {
      type: String,
      required: true,
      default: "en-US"
    },
    recognitionOnCallStart: {
      type: Boolean,
      required: false,
      default: false
    },
    isConversationEdited: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: { RealTimeAssistant },
  data () {
    return {
      microphoneAccessGranted: true,
      permissionStatus: null,
      showMicrophoneSelection: false,
      preferredMicrophoneId: null,
      baoRealTimeAssistantExtended: true,
      recognitionRunning: false,
      tryToStartRecognition: true,
      recognition: null,
      showTranscript: false,
      stopped: false,
      error: null,
      staticTextDefault: {
        SpeechRecognitionLabel: "Speech Analysis",
        stopSpeechRecognition: "Stop Speech Analysis",
        startSpeechRecognition: "Start Speech Analysis",
        noMicrophoneAccessToolTip: "bao has no access to the microphone",
        noMicrophoneAccessLabel: "Inactive Microphone",
        noMicrophoneAccessMessage: "Turn on microphone to use Speech Analysis",
        audioInputLabel: "Audio-Input",
        microphoneAccessModalTitle: "Give bao access to your microphone",
        microphoneAccessModalStep1: "Click the icon left to your browser URL",
        microphoneAccessModalStep2: "Turn on the microphone by clicking the toggle",
        microphoneActivateButtonLabel: "Activate"
      },
      callStartTime: 0,
      totalTimeCounter: null,
      timeOut: 1000, // milliseconds
      speakingStart: 0,
      intermediateSpeakingStart: 0,
      intermediateDuration: 0,
      intermediateTranscriptCount: 0,
      lastIntermediateTranscript: "",
      interimResult: "",
      callTranscriptItems: [],
      audioDevices: [],
      callTranscriptItemsWordCount: 0,
      talkingDurationCall: 0,
      totalCallDuration: 0,
      isInitialDataSet: false,
      activeItemStartTime: 0,
      monitorInterval: null
    }
  },
  computed: {
    callId () {
      return currentCallStore.getCallId
    },
    callItems () {
      return [...this.playbookItems, ...this.loadedData]
    },
    ifItemsRelevantForTextMatchExist () {
      return !!this.callItems.filter(item => item.relevant_for_text_match).length
    },
    playbookItems () {
      return currentCallStore.playbookItems
    },
    loadedData () {
      return this.playbookItems.length > 0
        ? this.getLoadedData(this.playbookItems)
        : []
    },
    activeItem () {
      return currentCallStore.getActiveItem
    },
    callTalkingShare () {
      /**
       * sum(call_items.transcripts.talking_duration) / total call duration
       * returns: Percentage (%)
       */
      // TODO: This should just be a simple variable being updated whenever there is a new total time!
      const talkingShareCall =
        this.totalCallDuration === 0
          ? 0
          : (this.talkingDurationCall / this.totalCallDuration) * 100
      return Math.min(talkingShareCall, 100)
    },
    callTalkingSpeed () {
      /**
       * total transcripts word count / sum(talking_items.transcripts.talking_duration)
       * returns: words per minute
       */
      return this.talkingDurationCall === 0
        ? 0
        : (this.callTranscriptItemsWordCount / this.talkingDurationCall) *
            (60 * 1000)
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](
        this.$options.name,
        this.staticTextDefault
      )
    }
  },
  watch: {
    activeItem (newItem, oldItem) {
      if (!_isEqual(oldItem, newItem)) {
        if (this.isInitialDataSet) {
          const activeItemEndTime = Date.now()
          const activeItemDuration = activeItemEndTime - this.activeItemStartTime
          this.activeItemStartTime = activeItemEndTime

          this.updateItemActiveDuration(oldItem, activeItemDuration)
        }
        if (!newItem.callItem && !newItem.callItemPromise) {
          currentCallStore.saveCallItem(newItem)
        } // now call_item is created with transcripts null
      }
    }
  },
  mounted () {
    this.preferredMicrophoneId = getCookie("preferredMicrophoneId")
    this.callStartTime = Date.now()
    this.setInitialData()

    // Only add the monitor if the conversation is not being edited
    if (!this.isConversationEdited) this.monitorActiveRecognition()
    window.addEventListener("beforeunload", this.handleTabClose)

    // check if microphone access is granted
    navigator.permissions.query({ name: "microphone" }).then((permissionStatus) => {
      this.microphoneAccessGranted = permissionStatus.state === "granted"
      this.permissionStatus = permissionStatus
      this.permissionStatus.onchange = () => {
        console.log("Microphone permission status has changed to", permissionStatus.state)
        this.microphoneAccessGranted = permissionStatus.state === "granted"
        this.$emit("microphone-state-changed", this.microphoneAccessGranted)
        if (this.microphoneAccessGranted && !this.recognitionRunning) {
          this.startRecognition()
        } else {
          this.endRecognition()
        }
      }
    })
  },
  beforeDestroy: function () {
    // This just saves an unnecessary call to the server when component is destroyed but recognition is not running
    if (!this.recognitionRunning) return

    if (!this.isConversationEdited) this.resetCallAudioStoreAndSaveCallTranscripts()
    this.stopSpeechRecognitionAndCleanup()
  },
  methods: {
    ...realTimeAssistantService.methods,
    ...mapMutations({
      resetCallAudioStore: "callAudioStore/resetCallAudioStore"
    }),
    ...mapActions({
      saveCallTranscripts: "callAudioStore/saveCallTranscripts"
    }),
    handleSpeechBtnClick () {
      if (this.microphoneAccessGranted) this.recognitionRunning ? this.endRecognition() : this.startRecognition()
    },
    getLoadedData (playbookItems) {
      const loadedDataAllItems = []
      playbookItems.forEach(playbookItem => {
        if (playbookItem.loadedData && playbookItem.loadedData.length > 0) {
          playbookItem.loadedData.forEach(loadedData => {
            if (
              loadedData.selected_choice &&
              loadedData.selected_choice.workflow &&
              Array.isArray(loadedData.selected_choice.workflow.children)
            ) {
              loadedDataAllItems.push(
                ...loadedData.selected_choice.workflow.children
              )
            }
          })
        }
      })
      return loadedDataAllItems
    },
    setInitialData () {
      /**
       * sets initial call duration and initial transcript data for the call and call items.
       * starts speech recognition when conversation is started.
       */
      this.setInitialTranscripts(this.callItems, this.callId)
      this.getTranscriptSummary(this.callId)
        .then(data => {
          const summary = data[0]
          if (
            !!summary &&
            !!summary.call_duration &&
            summary.call_items_transcripts
          ) {
            // set start time for call. This defaults to Date.now() for new calls
            // but for older calls, we assume the call started "summary.call_duration" ago
            this.callStartTime -= summary.call_duration
            this.totalCallDuration = summary.call_duration

            // TODO: this has to be revamped; transcript used is from speech recognition not bao-audio
            this.callTranscriptItemsWordCount = summary.talking_speed * summary.talking_duration / (60 * 1000)

            // set initial talking duration for call
            this.talkingDurationCall = summary.talking_duration
            const calculatedDataForItems = summary.call_items_transcripts

            // set durations for each item that has transcript saved
            this.callItems.forEach(item => {
              if (item.call_item) {
                const calculatedDataForItem = calculatedDataForItems.filter(
                  transcriptItem =>
                    transcriptItem.call_item === item.call_item.id
                )
                if (calculatedDataForItem.length > 0) {
                  this.$set(
                    item,
                    "activeDuration",
                    calculatedDataForItem[0].active_duration
                  )
                  this.$set(
                    item,
                    "talkingDuration",
                    calculatedDataForItem[0].talking_duration
                  )
                }
              }
            })
          }

          this.isInitialDataSet = true
          this.activeItemStartTime = Date.now()

          // save call items relevant for text match if not, bufferAction will fail
          // TODO: check why this is important for saving transcripts
          this.saveCallItemsRelevantForTextMatch()

          // The speech recognition shouldn't start automatically if the user is editing the call
          if (this.recognitionOnCallStart && !this.isConversationEdited) {
            this.startCallDurationTimer()
            this.startRecognition()
          }
        })
        .catch(error => {
          this.error = error.response
        })
    },
    setInitialTranscripts (callItems, callId) {
      /**
       * sets initial transcript data for call and talking items when component mounts.
       */
      this.getTranscripts(callId).then(data => {
        const allTranscripts = data
        if (allTranscripts.length > 0) {
          // set transcripts for each talking item
          callItems.forEach(item => {
            if (item.call_item) {
              const itemTranscripts = allTranscripts.filter(
                transcriptItem => transcriptItem.call_item === item.call_item.id
              )
              if (itemTranscripts.length > 0) {
                this.$set(item, "transcripts", itemTranscripts)
              }
            }
          })
        }
      })
    },
    startRecognition () {
      const isSpeechRecognitionActiveInAnotherTab = getCookie("activeSpeechRecognition") === "true"
      console.log("SR: Speech recognition started.")
      if (!recognition) {
        this.error =
          "Speech Recognition is not available on this browser. Please use Chrome or Firefox"
        return false
      }

      if (!isSpeechRecognitionActiveInAnotherTab) {
        setCookie("activeSpeechRecognition", "true")
        this.recognitionRunning = true

        // This will run when the speech recognition service returns a result
        recognition.onstart = function () {
        // console.log(
        //   "Voice recognition started. Try speaking into the microphone."
        // )
        }
        const that = this
        recognition.onresult = function (event) {
          const result = event.results[event.results.length - 1]
          const transcript = result[0].transcript
          that.interimResult = transcript

          const intermediateSpeechDuration = Date.now() - that.intermediateSpeakingStart // speech duration for each intermediate transcript
          that.intermediateSpeakingStart = Date.now()

          const lastIntermediateResult = that.lastIntermediateTranscript.split(" ")
          if (that.lastIntermediateTranscript && lastIntermediateResult.length > 0) {
            const lastWordOflastIntermediateResult = lastIntermediateResult[lastIntermediateResult.length - 1]
            const currentIntermediateResult = transcript.split(" ")

            const lastWordOfCurrentIntermediateResult = (transcript && currentIntermediateResult.length > 0) ? currentIntermediateResult[currentIntermediateResult.length - 1] : ""

            // increase the transcription count only if last words are different. there are possibilities that two or more words can be same\\
            // but that is the margin of error we have for intermediate result which will be corrected once the full transcript is available
            if (lastWordOflastIntermediateResult !== lastWordOfCurrentIntermediateResult) {
              that.callTranscriptItemsWordCount += 1
              // this variable stores total intermediate transcript count which is used later for correction when whole context of transcript is available
              that.intermediateTranscriptCount += 1
            }
          } else {
            that.callTranscriptItemsWordCount += 1
            that.intermediateTranscriptCount += 1
          }
          // update lastIntermediateTranscript by current transcript
          that.lastIntermediateTranscript = transcript
          that.talkingDurationCall += intermediateSpeechDuration

          // this variable stores total intermediate duration which is used later for correction when whole context of transcript is available
          that.intermediateDuration = that.intermediateDuration + intermediateSpeechDuration

          if (result.isFinal) {
            const talkingItem = that.callItems.filter(item => item.id === that.activeItem.id)[0]
            const speechDuration = Date.now() - that.speakingStart // speech duration for each transcript
            that.speakingStart = Date.now()

            // add new transcript to callTranscriptItems
            that.callTranscriptItems.push(transcript)
            // subtract the total intermediate transcript added and total duration added for the calculation of talking share and word per min
            // this step corrects the duration and transcript as in intermediate times, there are some chances of error being added/considered
            that.callTranscriptItemsWordCount = that.callTranscriptItemsWordCount + that.countWords(transcript) - that.intermediateTranscriptCount
            that.talkingDurationCall = that.talkingDurationCall + speechDuration - that.intermediateDuration

            // reset the variables used to compute the intermediate results during voice recognition
            that.intermediateTranscriptCount = 0
            that.intermediateDuration = 0
            that.lastIntermediateTranscript = ""
            if (talkingItem.relevant_for_text_match) {
            // add raw transcript data to active item save call and active item transcripts.
              that.addTranscriptToItem(transcript, speechDuration, talkingItem)

              that.saveCallItemTranscripts(that.getCallItemTranscript(talkingItem))
            }
            recognition.stop()
          }
        }

        recognition.onspeechstart = function (event) {
          that.speakingStart = Date.now()
          that.intermediateSpeakingStart = Date.now()
          that.intermediateTranscriptCount = 0
          that.intermediateDuration = 0
          that.lastIntermediateTranscript = ""
        }

        recognition.onend = function (event) {
          console.log("SR: Speech recognition ended.", event)
          if (that.recognitionRunning) {
          // keep it going.
            recognition.start()
          }
        }

        recognition.onerror = function (event) {
        // TODO: Leaving this here for debugging purposes, will take it out after pluxee issue is resolved - Rah
          console.log("SR: Speech recognition error.", event)
          if (event.error === "aborted" || event.error === "not-allowed") {
            that.recognitionRunning = false
            that.tryToStartRecognition = false
            setCookie("activeSpeechRecognition", "false")
          }
        }

        // start recognition
        recognition.continuous = false
        recognition.interimResults = true
        recognition.lang = that.language
        recognition.start()
      }
    },
    startCallDurationTimer () {
      this.totalTimeCounter = setInterval(() => {
        this.totalCallDuration = Date.now() - this.callStartTime
      }, this.timeOut)
    },
    saveCallItemsRelevantForTextMatch () {
      this.callItems.forEach(item => {
        if (item.relevant_for_text_match) currentCallStore.saveCallItem(item)
      })
    },
    addTranscriptToItem (transcript, speechDuration, talkingItem) {
      // todo: check why bufferAction is used here.
      this.bufferAction(talkingItem, response => {
        if (response) talkingItem.callItem = response.data

        const transcripts = talkingItem.transcripts
          ? talkingItem.transcripts
          : []

        // update active item talking duration
        this.updateItemTalkingDuration(talkingItem, speechDuration)

        // add transcript data to call item
        const newTranscriptData = {
          text: transcript,
          talking_duration: speechDuration,
          call_item: talkingItem.callItem.id
        }
        transcripts.push(newTranscriptData)
        this.$set(talkingItem, "transcripts", transcripts)

        // save transcript data (raw data as well calculated data for call and call item)
        this.getAndSaveTranscriptData(newTranscriptData, talkingItem)
      })
    },
    getAndSaveTranscriptData (newTranscript, item) {
      // note: this method is called whenever there is a new transcript result.
      const dataToSave = {
        ...newTranscript,
        call_item_transcript: this.getCallItemTranscript(item),
        call_transcript: this.getCallTranscript()
      }
      this.saveTranscripts(dataToSave)
    },
    resetCallAudioStoreAndSaveCallTranscripts () {
      // save active item active calculated data
      // First reset the store so old data is cleared
      this.resetCallAudioStore()
      const callTranscript = this.getCallTranscript()
      const shouldCheckForTranscript = !!(this.ifItemsRelevantForTextMatchExist && !!this.callTranscriptItems.length)

      // save latest transcript data for call
      this.saveCallTranscripts({ callTranscript, shouldCheckForTranscript })
    },
    getCallItemTranscript (item) {
      if (item.id === this.activeItem.id) {
        const activeItemEndTime = Date.now()
        const activeItemDuration = activeItemEndTime - this.activeItemStartTime
        this.activeItemStartTime = activeItemEndTime
        this.updateItemActiveDuration(item, activeItemDuration)
      }
      const talkingDuration = item.talkingDuration || 0
      const activeDuration = item.activeDuration || 0
      const wordCount = this.getItemTranscriptsWordCount(item)

      return {
        talking_speed: talkingDuration
          ? (wordCount / talkingDuration) * (60 * 1000)
          : 0, // WPM
        talking_share: Math.min(
          activeDuration ? (talkingDuration / activeDuration) * 100 : 0,
          100
        ), // %
        active_duration: activeDuration,
        talking_duration: talkingDuration,
        call_item: item.callItem.id
      }
    },
    getCallTranscript () {
      return {
        talking_speed: this.callTalkingSpeed,
        call_duration: Date.now() - this.callStartTime,
        talking_duration: this.talkingDurationCall,
        call: this.callId
      }
    },
    getItemTranscriptsWordCount (talkingItem) {
      /**
       * sum(transcripts.word_count)
       * returns: Number
       */
      if (!!talkingItem.transcripts && talkingItem.transcripts.length > 0) {
        const transcriptWordCounts = talkingItem.transcripts.map(item =>
          this.countWords(item.text)
        )
        return this.getArraySum(transcriptWordCounts)
      }
      return 0
    },
    updateItemActiveDuration (talkingItem, duration) {
      const updatedDuration = talkingItem.activeDuration
        ? talkingItem.activeDuration + duration
        : duration
      this.$set(talkingItem, "activeDuration", updatedDuration)
    },
    updateItemTalkingDuration (talkingItem, speechDuration) {
      const updatedDuration = talkingItem.talkingDuration
        ? talkingItem.talkingDuration + speechDuration
        : speechDuration
      this.$set(talkingItem, "talkingDuration", updatedDuration)
    },
    bufferAction (item, callback) {
      if (item.callItemPromise) {
        return item.callItemPromise.then(response => callback(response))
      }
      return new Promise((resolve, reject) => {
        callback()
        resolve()
      })
    },
    countWords (str) {
      if (str && str.length > 0) return str.trim().split(/\s+/).length
      return 0
    },
    getArraySum (array) {
      return array.reduce((redVal, item) => redVal + item, 0)
    },
    endRecognition () {
      if (recognition && this.recognitionRunning) {
        if (this.activeItem.relevant_for_text_match) {
          this.saveCallItemTranscripts(this.getCallItemTranscript(this.activeItem))
        }
        this.recognitionRunning = false
        this.tryToStartRecognition = false
        recognition.stop()
        setCookie("activeSpeechRecognition", "false")
      }
    },
    monitorActiveRecognition () {
      this.monitorInterval = setInterval(() => {
        const isSpeechRecognitionActiveInAnotherTab = getCookie("activeSpeechRecognition") === "true"
        if (!isSpeechRecognitionActiveInAnotherTab && this.tryToStartRecognition) {
          this.startRecognition()
        }
      }, 1000)
    },
    handleTabClose () {
      if (this.recognitionRunning) {
        setCookie("activeSpeechRecognition", "false")
      }
    },
    async handleMicrophoneSelectionClick () {
      this.showMicrophoneSelection = !this.showMicrophoneSelection
      if (!this.showMicrophoneSelection) { return }

      const allDevices = await listValidAudioDevices()
      const uniqueGroupIds = new Set()

      // Need to filter out based on GroupID, since default device gets two enteries with different deviceIds
      // But same GroupId
      this.audioDevices = allDevices.filter(device => {
        return uniqueGroupIds.has(device.groupId) ? false : uniqueGroupIds.add(device.groupId)
      })
    },
    selectPreferredMicrophone (deviceId) {
      setCookie("preferredMicrophoneId", deviceId)
      this.preferredMicrophoneId = deviceId
      // This triggers switch of audio recording device and starting a new recording session
      this.$emit("microphone-state-changed", this.microphoneAccessGranted)
    },
    stopSpeechRecognitionAndCleanup () {
      if (this.permissionStatus) {
        this.permissionStatus.onchange = null
      }

      clearInterval(this.totalTimeCounter)
      this.endRecognition()

      clearInterval(this.monitorInterval)
      window.removeEventListener("beforeunload", this.handleTabClose)
    }
  }
}
</script>

<style lang="scss" scoped>

.warning-icon {
  position: absolute;
  top: -6px;
  right: -6px;
}

.microphone-selector-container{
  position: absolute;
  top: 45px;
  right: 60px;
  background: #FFFFFF;
  z-index: 999999999;
  border-radius: 12px;
  min-width: 250px;
  pointer-events: auto;
  box-shadow: 6px 8px 20px 5px #2A2D520F;
}

.microphone-selector-container div[for] {
  cursor: pointer;
  transition: background-color 0.2s;
  border-radius: 12px;
}

.microphone-selector-container div[for]:hover {
  background-color: #f0f0f0;
}

.audio-input-label {
  font-size: 12px;
}

.inactive-microphone-container{
  background: #2A2D520F;
  box-shadow: 6px 8px 20px 5px #2A2D520F;
  border-radius: 12px;
}

.activate-microphone-button {
  width: fit-content;
  padding: 7px 14px;
  border-radius: 16px;
  background: $slate;
  color: white;
  font-size: 12px;
  line-height: 14.4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  &:hover {
    background-color: $slate80;
  }
}

</style>
