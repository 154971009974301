<template>
  <div>
    <div v-if="!!callItem && activityCounterparts && activityCounterparts.length > 0"
         class="mt-3"
    >
      <!-- subject -->
      <div class="d-flex">
        <div class="row col-3 mt-3">
          {{ staticText.subjectLabel }}
        </div>
        <div class="w-100">
          <b-form-input v-model="subject"
                        :placeholder="callItem.name"
                        :state="showSubjectError"
                        class="d-flex ml-auto mb-3 no-border shadow-sm"
                        type="text"
          ></b-form-input>
        </div>
      </div>

      <!-- description -->
      <div class="d-flex">
        <div class="row col-3 mt-3">
          {{ staticText.descriptionLabel }}
        </div>
        <div class="w-100">
          <b-form-textarea v-model="description"
                           class="d-flex ml-auto mb-3 shadow-sm"
                           type="text"
          ></b-form-textarea>
        </div>
      </div>

      <!-- Owner -->
      <div v-if="!!users && users.length>0"
           class="d-flex mb-2"
      >
        <div class="row col-3 mt-3">
          {{ staticText.ownerLabel }}
        </div>
        <multiselect v-model="owner"
                     :options="users"
                     :show-labels="false"
                     :allowEmpty="false"
                     track-by="id"
                     label="name"
        ></multiselect>
      </div>

      <!-- call from -->
      <div v-if="!!users && users.length>0"
           class="d-flex mb-2"
      >
        <div class="row col-3 mt-3">
          {{ staticText.callFromLabel }}
        </div>
        <multiselect v-model="callFrom"
                     :options="users"
                     :show-labels="false"
                     :allowEmpty="false"
                     track-by="id"
                     label="name"
        ></multiselect>
      </div>

      <!-- call to -->
      <div class="d-flex mb-2">
        <div class="row col-3 mt-3">
          {{ staticText.callToLabel }}
        </div>
        <BaseCounterpartSelector
          v-if="activityCounterparts"
          :pre-selected-counterparts="activityCounterparts[0] || null"
          :items="activityCounterparts"
          @input="value => callTo=value"
        />
      </div>

      <!-- regarding -->
      <div class="d-flex mb-2">
        <div class="row col-3 mt-3">
          {{ staticText.regardingLabel }}
        </div>
        <BaseCounterpartSelector
          v-if="activityCounterparts"
          :pre-selected-counterparts="activityCounterparts[0] || null"
          :items="activityCounterparts"
          @input="value => regarding=value"
        />
      </div>

      <!-- priority -->
      <div class="d-flex mb-2"
      >
        <div class="row col-6 mt-3">
          {{ staticText.priorityLabel }}
        </div>
        <multiselect v-model="priority"
                     :options="priorityOptions"
                     :show-labels="false"
                     :allowEmpty="false"
                     track-by="value"
                     label="label"
        ></multiselect>
      </div>

      <!-- direction -->
      <div class="d-flex mb-2"
      >
        <div class="row col-6 mt-3">
          {{ staticText.directionLabel }}
        </div>
        <multiselect v-model="direction"
                     :options="directionOptions"
                     :show-labels="false"
                     :allowEmpty="false"
                     track-by="value"
                     label="label"
        ></multiselect>
      </div>

      <!-- phoneNumber -->
      <div class="d-flex mb-2"
      >
        <div class="row col-6 mt-3">
          {{ staticText.phoneNumberLabel }}
        </div>
        <b-form-input v-model="phoneNumber"
        ></b-form-input>
      </div>

      <!-- duration -->
      <div class="d-flex mb-2"
      >
        <div class="row col-6 mt-3">
          {{ staticText.durationLabel }}
        </div>
        <b-form-input v-model="duration"
                      type="number"
        ></b-form-input>
      </div>

      <!-- due -->
      <div class="d-flex mb-2"
      >
        <div class="row col-6 mt-3">
          {{ staticText.dueDateLabel }}
        </div>
        <VueCtkDateTimePicker
          v-model="due"
          :label="staticText.dueDatePlaceholder"
          :format="datetimeFormat"
          :error="datetimeError"
          formatted="lll"
          :right="true"
        />
      </div>

      <!-- create button -->
      <div class="w-100 d-flex justify-content-end">
        <ActivityCreateButton
          :is-created="created"
          :is-creating="creating"
          :button-label="staticText.createPhoneCallBtn"
          :created-label="staticText.createdLabel"
          :creating-label="staticText.creatingLabel"
          :disabled="isPreviewMode"
          @create="createPhoneCall"
        />
      </div>
    </div>

    <div v-else>
      <base-alert
        id="no-counterpart-alert-202402200212"
        :alert-message="staticText.alertText"
        :show-alert="true"
        variant="danger"
        class="mt-3"
      ></base-alert>
    </div>
  </div>
</template>

<script>
import { ActivityCreatorMixin } from "@/apps/call/CallViewItem_components/ActivityCreatorMixin"

export default {
  name: "PhoneCallActivity",
  mixins: [ActivityCreatorMixin],
  props: {
    callItem: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      description: null,
      owner: null,
      callFrom: null,
      callTo: null,
      regarding: null,
      direction: null,
      priority: null,
      phoneNumber: null,
      duration: 0,
      due: null,
      datetimeFormat: "YYYY-MM-DD HH:mm",
      staticTextDefault: {
        createdLabel: "Phone Call Created",
        creatingLabel: "Creating Phone Call...",
        createPhoneCallBtn: "Create Phone Call",
        callFromLabel: "Call From",
        alertText: "To create a Phone Call select a counterpart in the top-right corner.",
        linkToActivityLabel: "Link to Phone Call",
        callToLabel: "Call To",
        regardingLabel: "Regarding",
        ownerLabel: "Owner",
        priorityLabel: "Priority",
        lowLabel: "Low",
        normalLabel: "Normal",
        highLabel: "High",
        directionLabel: "Direction",
        durationLabel: "Duration (in Minutes)",
        phoneNumberLabel: "Phone Number",
        inComingLabel: "Incoming",
        outGoingLabel: "Outgoing",
        dueDateLabel: "Due",
        dueDatePlaceholder: "Select Due Date",
        warningMessage: "Error in creating Phone Call.",
        descriptionLabel: "Description"
      }
    }
  },
  computed: {
    priorityOptions () {
      return [
        { label: this.staticText.lowLabel, value: 0 },
        { label: this.staticText.normalLabel, value: 1 },
        { label: this.staticText.highLabel, value: 2 }
      ]
    },
    directionOptions () {
      return [
        { label: this.staticText.inComingLabel, value: false },
        { label: this.staticText.outGoingLabel, value: true }
      ]
    }
  },

  async mounted () {
    this.itemNotes = this.callItem.notes
    this.subject = this.callItem.name
    this.selectedCounterpart = !!this.activityCounterparts && !!this.activityCounterparts.length ? this.activityCounterparts[0] : null
    this.priority = this.priorityOptions[1]
    this.direction = this.directionOptions[1]

    if (this.selectedCounterpart) {
      await this.getActivityUsers(this.selectedCounterpart.crm_service)
    }

    this.getPreselection()
  },

  watch: {
    activityCounterparts: {
      async handler (val) {
        this.created = false
        if (!this.selectedCounterpart) {
          this.selectedCounterpart = val[0]
          if (this.selectedCounterpart) {
            await this.getActivityUsers(this.selectedCounterpart.crm_service).then(() => this.getPreselection()
            )
          }
        }
      },
      deep: true
    }
  },
  methods: {
    getPreselection () {
      if (this.selectedCounterpart) {
        this.regarding = this.selectedCounterpart
      }
      if (this.selectedUser) {
        this.owner = this.selectedUser
        this.callFrom = this.selectedUser
      }
    },
    async createPhoneCall () {
      if (!this.subject) {
        this.showWarningMessage(this.staticText.warningTitle, this.staticText.emptySubjectMessage)
        return
      }
      if (!this.due) {
        this.datetimeError = true
        return
      }

      const payload = {
        subject: this.subject,
        description: this.description,
        phone_number: this.phoneNumber,
        owner: this.owner,
        call_from: this.callFrom,
        call_to: this.callTo,
        regarding: this.regarding,
        priority: this.priority,
        direction: this.direction,
        duration_in_minutes: this.duration,
        due_date_time: this.moment.utc(new Date(this.due)).format("YYYY-MM-DD HH:mm")
      }
      await this.createActivity("phoneCall", payload)
    },
    getNotesToUpdate () {
      let notes = ""
      notes += `<p>${this.staticText.subjectLabel}: ${this.subject}</p>`
      notes += `<p>${this.staticText.callToLabel}: ${this.callTo.name}</p>`
      notes += `<p>${this.staticText.callFromLabel}: ${this.callFrom.name}</p>`
      if (this.description) {
        notes += `<p>${this.staticText.descriptionLabel}: ${this.description}</p>`
      }
      return notes
    }
  }
}
</script>
