<template>
  <div id="objection-modal-container-202101121309">
    <!-- Shortcut Modal -->
    <b-modal
      id="objection-modal-202101262157"
      size="lg"
      hide-footer
      no-fade
      @hidden="objectionModalClosed(null)"
    >
      <template v-slot:modal-title>
        <img src="../../../../public/img/icons/objections-icon.svg" class="mr-2"/>
        <span v-if="selectedObjection && selectedObjection.selected_choice">
          {{ selectedObjection.selected_choice.name }}
        </span>
      </template>

      <template v-slot:modal-header-close>
        <img src="../../../../public/img/icons/close-icon.svg"/>
      </template>

      <div v-if="selectedObjectionHasChildren">
        <div v-for="(objectionItem, index) in selectedObjectionWorkflow.children"
             :key="index"
             class="w-100"
        >
          <call-view-item
            :item="objectionItem"
            :call-view-item-index="index"
            :main-container="parentContainerOfSelectedObjection"
            :additional-data="additionalData"
            :playbook-id="playbookId"
            :level="5"
            @close-objection-modal="closeObjectionModal"
          ></call-view-item>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import CallViewItem from "../CallViewItem"
import { currentCallStore } from "@/store/services/callStore"
import { mapStateToCurrentCallStore } from "@/apps/call/utils"

export default {
  name: "CallViewObjectionModal",
  components: {
    CallViewItem
  },
  data () {
    return {
      staticTextDefault: {}
    }
  },
  computed: {
    ...mapStateToCurrentCallStore([
      "call",
      "playbookItems",
      "selectedObjection",
      "parentContainerOfSelectedObjection",
      "displayObjectionModal",
      "activeItem"
    ]),
    dragOptions () {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost"
      }
    },
    selectedObjectionWorkflow () {
      return this.selectedObjection.selected_choice.workflow
    },
    additionalData () {
      return this.selectedObjection
    },
    playbookId () {
      return this.selectedObjection.selected_choice.talkscript
    },
    selectedObjectionHasChildren () {
      return !!this.selectedObjection &&
        this.selectedObjection.selected_choice &&
        this.selectedObjection.selected_choice.workflow &&
        this.selectedObjection.selected_choice.workflow.children
    },
    staticText () {
      return this.$store.getters["I18nStore/getI18n"](this.$options.name, this.staticTextDefault)
    }
  },
  watch: {
    displayObjectionModal (val) {
      if (val) this.$bvModal.show("objection-modal-202101262157")
    }
  },
  methods: {
    objectionModalClosed (value) {
      currentCallStore.objectionModalClosed(value)
    },
    objectionWasSelected (payload) {
      currentCallStore.objectionWasSelected(payload)
    },
    closeObjectionModal () {
      this.$bvModal.hide("objection-modal-202101262157")
    }
  }
}
</script>

<style scoped lang="scss">
</style>
